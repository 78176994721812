import Typography from '@mui/material/Typography';
import { ContentStatus } from '../enums/programs.enums';
import { ListItem, Icon } from '@mui/material';
export function ListChapter(props: any) {
    return (
        <ListItem
            sx={{
                height: 35,
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                paddingLeft: '8px',
            }}
        >
            <Icon
                sx={{
                    borderRadius: "100%",
                    border: "2px solid black",
                    marginY: "-2px",
                    backgroundColor: (props.chapter.contents.every((content: any) => (content.status === ContentStatus.Completed || content.status === ContentStatus.Perfect)) ? "green" : "gray")
                }}
            >
                <Typography variant='body1'
                    sx={{ marginTop: 0.36 }}
                    fontSize={13}
                    fontWeight={"bold"}
                    color={"white"}>
                    C{props.indexChapter + 1}
                </Typography>
            </Icon>
            <Typography key={"chapterText:" + props.indexChapter}
                variant='body2'
                sx={{
                    fontWeight: 'bold',
                    marginLeft: 1
                }}
                color={'black'}>
                {props.openDelay ? props.chapter.name : null}
            </Typography>
        </ListItem>
    )
}