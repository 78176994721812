import * as React from 'react';
import { useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, tagList: readonly string[], theme: Theme) {
  return {
    fontWeight:
      tagList.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type Props = {
  tagList: string[],
  setTagList: any
}
const CourseChip: React.FC<Props> = ({ tagList, setTagList }) => {
  const theme = useTheme();

  const [allCoursesType, setAllCoursesType] = useState<readonly string[]>([])

  useEffect(() => {
    const token = localStorage.getItem('token');
    MetadataApi.get("types/getall", {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      setAllCoursesType(res.data);
    })
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof tagList>) => {
    const {
      target: { value },
    } = event;
    setTagList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id="demo-multiple-chip-label" size='small'>Tags</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        size='small'
        value={tagList}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} size="small" />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {allCoursesType.map((name) => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, tagList, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CourseChip;