
import { Box, Fab, Pagination, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AxiosResponse } from 'axios';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MetadataApi from '../apis/metadata.api';
import { myContext } from '../contexts/Context';
import { setContentStatus, setCourseCertificate, setCourseStatus } from '../stores/slices/activeCourseSlice';
import { RootState } from '../stores/store';
import MessageDialog from './MessageDialog';
import QuestionElement from './QuestionElement';
import HelpIcon from '@mui/icons-material/Help';
import { Link } from 'react-router-dom';
import MessageResultDialog from './MessageResultDialog';
import { ChatBox } from './ChatBox';
import MessageDialogCertificate from './MessageDialogCertificate';
import { CourseCountdown } from './CourseCountdown';
export interface Answer {
  id: number,
  alternative1: boolean,
  alternative2: boolean,
  alternative3: boolean,
  alternative4: boolean,
  alternative5: boolean,
}

type Props = { contentId: number,expired:boolean,setCourseTimeRemaining:Function }

const QuestionLesson: React.FC<Props> = ({ contentId,expired ,setCourseTimeRemaining}) => {
  const ctx = useContext(myContext);
  const courseState = useSelector((state: RootState) => state.activeCourse);
  const dispatch = useDispatch();
  const [numberNotifications, setNumberNotifications] = useState<number>(0)
  const [errorTrigger, setErrorTrigger] = useState(0);
  const [resultTrigger, setResultTrigger] = useState(0);
  const [certTrigger, setCertTrigger] = useState(0);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [numberAnswered, setNumberAnswered] = useState<number>(0)
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [perfectCertTrigger, setPerfectCertTrigger] = useState(0);
  useEffect(() => {
    setNumberAnswered(0)
    setPage(1)
  }, [contentId])



  useEffect(() => {
    if (!ctx || !ctx.user || ctx.loading) return;
    setLoading(true);
    const token = localStorage.getItem('token');
    MetadataApi.post(`learncontents/getcontent`, {
      contentId: contentId,
      username: ctx.user.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      //('getcontent_learn:', res.data)

      setData(res.data)
      createAnswers(res.data)
      setLoading(false)
    }, () => {
      // window.location.href = "/"
    })
  }, [ctx, contentId])

  const createAnswers = (data: any) => {
    var answer_temp: Answer[] = [];
    for (var i = 0; i < data.question.length; ++i) {
      var answer: Answer = { id: data.question[i].id, alternative1: false, alternative2: false, alternative3: false, alternative4: false, alternative5: false };
      answer_temp.push(answer);
    }
    setAnswers(answer_temp);
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const getnumbernotifications = useCallback(() => {

    const token = localStorage.getItem('token');
    MetadataApi.post("learncomments/getnumbernotifications", {
      courseId: courseState.course.courseId,
      contentId: contentId,
      codelessonId: -1,
      username: ctx?.user?.username,
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {

      setNumberNotifications(res.data)
    })


  }, [contentId, courseState.course.courseId, ctx.user])

  useEffect(() => {
    getnumbernotifications()
  }, [getnumbernotifications])
  const handleClick = (e: any) => {
    if (!ctx || !ctx.user || ctx.loading) return;
    //console.log('answers',answers);
    const token = localStorage.getItem('token');
    MetadataApi.post(`learncontents/validatequestion`, {
      contentId: contentId,
      username: ctx.user.username,
      answers: answers,
      courseId: courseState.course.courseId
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      if(res.data){
          //console.log('validatequestion:', res.data)
        dispatch(setContentStatus(res.data.contentStatus));

        dispatch(setCourseStatus(res.data.course.courseStatus));
        if (res.data.course.courseCertificate && res.data.course.courseCertificate !== '') {
          dispatch(setCourseCertificate(res.data.course.courseCertificate));
          if (res.data.course.isPerfect) { //perfect case
            setPerfectCertTrigger((perfectCertTrigger) => perfectCertTrigger + 1);
          } else {
            setCertTrigger((certTrigger) => certTrigger + 1);
          } 
        } else {
          setResultTrigger((resultTrigger) => resultTrigger + 1);
        }
      }
    
    }, () => {
      setErrorTrigger((errorTrigger) => errorTrigger + 1);
    })
  }
  if (!contentId || !ctx) return <>Error</>
  return (
    <>
      <MessageResultDialog trigger={resultTrigger} contentState={courseState.content.status} />
      <MessageDialog trigger={errorTrigger} message={'Error al guardar en Base de Datos'} />
      <MessageDialogCertificate trigger={certTrigger} message={'Felicidades, has finalizado el curso y obtenido tu certificado, puede intentar terminar el curso con estado Perfecto completando todos los ejecicios perfectamente.'} isPerfect={false}/>
      <MessageDialogCertificate trigger={perfectCertTrigger} message={'Felicidades, has finalizado el curso de manera perfecta y obtenido tu certificado '} isPerfect={true}/>
   
      {!loading ?
        <>
           <Link to="/help/Question" target={"_blank"} >
        <br />
        <Fab  /* className={`absolute-button ${isMobile ? 'mobile' : 'computer'}`} */ size="small" color="secondary" aria-label="add" sx={{ position: "absolute", right: { xs: 16, md: 16 } }}> <HelpIcon /> </Fab>
      </Link>
      {courseState.course.hours_limit?<Box
                sx={{
                  mt: 2,
                  position: "absolute",
                  right: 30,
                  display: { xs: "none", md: "flex" },
                }}
              >
                <CourseCountdown course_hours={courseState.course.hours} start_date={courseState.course.start_date} isLearn={true} setCourseTimeRemaining={setCourseTimeRemaining} />
              </Box>:
          <ChatBox contentId={data.contentId} codelessonId={-1} setNumberNotifications={setNumberNotifications} numberNotifications={numberNotifications} getNumberNotifications={getnumbernotifications} page={page} multiplesChats={false} ></ChatBox>
      }
          <Grid container spacing={2} p={3}>
            <Grid item xs={12} md={5}>
              <Typography variant='h5' sx={{ textAlign: { xs: 'center', md: 'left' } }}>{data.name}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" sx={{ justifyContent: { xs: 'center', md: 'left' } }}>
                <Pagination
                  page={page}
                  count={data.question.length}
                  // defaultPage={data.question.length}
                  onChange={handleChange}
                  color="primary" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              {courseState.course.hours_limit ? (
                  <Box sx={{ display: { xs: "flex", md: "none" } }}>
                    <CourseCountdown course_hours={courseState.course.hours} start_date={courseState.course.start_date} isLearn={true} setCourseTimeRemaining={setCourseTimeRemaining} />
                  </Box>
                
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {data.question.map((item: any, index: number) => {
                return (
                  <Box key={index} hidden={(index + 1) !== page}>
                    <Typography variant='h6'>Pregunta {page}</Typography>
                    <QuestionElement item={item} answer={answers[index]} setNumberAnswered={setNumberAnswered} setPage={setPage} isLastPage={page === answers.length ? true : false} page={page} sendAvailable={(numberAnswered === answers.length ? false : true)} send={handleClick} expired={expired}/>

                  </Box>
                )
              })}
            </Grid>
            <Grid item xs={12}><br /></Grid>
          </Grid>
        </>
        :
        <Skeleton></Skeleton>
      }
    </>
  )
}

export default QuestionLesson;