import React, { useEffect, useState, useContext } from 'react';
import {Container,Typography,Button,Box,List,ListItemButton,ListItem,Link as ShortLink} from '@mui/material';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { Link } from 'react-router-dom';
import { myContext } from '../contexts/Context';
import { CourseStatus } from '../enums/programs.enums';
import { CertificateIcon, getIcon } from '../components/Icons';
import { CourseProgress } from '../components/CourseProgress';
import { CourseLimitHours } from '../components/CourseLimitHours';
import { CourseCountdown } from '../components/CourseCountdown';
import { timeRemaining } from '../common/HoursLeft';
export default function CourseDetail () {
  const ctx = useContext(myContext);
  const { id } = useParams();
  const navigate = useNavigate();
	const goBack = () => {navigate(-1);}
  const [expired, setExpired] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [courseTimeRemaining,setCourseTimeRemaining]=useState<number>(9999999)
  useEffect(()=>{
    if(courseTimeRemaining<=0)setExpired(true)
  },[courseTimeRemaining])
    useEffect(() => {
        if(ctx.loading) return;
        setLoading(true);
        if(ctx && ctx.user){
            const token = localStorage.getItem('token');
            //validate if user have the course
            MetadataApi.post(`learncourses/validatecourse`, 
                { courseId:id, username:ctx.user.username },
                { headers: ({ Authorization: 'Bearer '+token })}
            ).then((res: AxiosResponse) => {
                //console.log('validatecourse', res.data)
                if(!res.data){
                    //if not, then we get general course data
                    MetadataApi.get(`courses/getcourse/${id}/data`, {
                    }).then((res : AxiosResponse) => {
                        //console.log('getcourse',res.data)
                        setData(res.data)
                        setLoading(false)
                    }, () => {
                        window.location.href = "/"
                    })
                } else if(ctx.user){
                    //if user have the course, ask for user details
                    MetadataApi.post(`learncourses/getcourse`, { 
                        courseId:id,
                        username:ctx.user.username
                    },{ headers: ({
                        Authorization: 'Bearer ' + token
                        }),
                    }).then((res : AxiosResponse) => {
                      if (res.data.hours_limit&&res.data.start_date!==null) {
                        const secondsRemaining=timeRemaining(res.data.start_date,res.data.hours)
                            if(secondsRemaining<=0){
                                setExpired(true)
                            }
                      }
                        console.log('getcourse_learn:',res.data)
                        setData(res.data)
                        setLoading(false)
                    }, () => {
                        window.location.href = "/"
                    })
                }
            })
        } else {
            MetadataApi.get(`courses/getcourse/${id}/data`, {
            }).then((res : AxiosResponse) => {
                //console.log('getcourse',res.data)
               /*  const time=timeRemaining(res.data.start_date,res.data.hours)
                if(time<=0)setExpired(true)
                setCourseTimeRemaining(time) */
                setData(res.data)
                setLoading(false)
            }, () => {
                window.location.href = "/"
            })
        }
    },[ctx,id]);

    return (
        <Container className='centered'>
            <IconButton onClick={goBack}>
              <ChevronLeftIcon/>
            </IconButton>
            <Grid container spacing={2} justifyContent="center" pb={5}>
                <Grid item xs={12}>
                    <Typography variant='h4' align='center'>{!loading ? `${data.name}` : <Skeleton/> }</Typography>
                </Grid>
                <Grid item xs={12}>    
                    <Typography variant='body1' align='center' sx={{ whiteSpace: 'pre-line'}}>
                      {!loading ? `${data.description}` : <Skeleton height={200}/> }
                    </Typography>
                  {!loading ? 
                  <Grid>
                    {data.hours_limit ? (
                      <Typography variant="body1" component={"div"} align="center" sx={{ fontWeight: "bold", whiteSpace: "pre-line" }}>
                        *Este curso cuenta con un tiempo limite de {data.hours} horas
                      </Typography>
                    ) : (
                      <Typography variant="body1" component={"div"} align="center" sx={{ whiteSpace: "pre-line" }}
                      >
                        Tiempo Estimado:
                        <Box sx={{ fontWeight: "bold", display: "inline" }}>
                          {data.hours} horas
                        </Box>
                      </Typography>
                    )}
                      {data.hours_limit&&data.start_date!==null?<CourseCountdown start_date={data.start_date} course_hours={data.hours} isLearn={false} setCourseTimeRemaining={setCourseTimeRemaining}/>:null}
                        </Grid>
                        :
                        <Skeleton height={200}/>
                        }
                </Grid>
                {data&&data.certificate?<Grid item xs={12}><Typography align='center'><CertificateIcon size={50} url={data.certificate.code} /></Typography></Grid>:null}
                {!loading && data.professor_name && data.professor_name.length>1 && data.professor_link && data.professor_link.length>1 ?
                    <Grid item xs={12} alignItems={'center'}>
                        <Typography variant='body2' align='center'>Realizado por: <ShortLink href={data.professor_link} target='_blank'>{data.professor_name}</ShortLink></Typography>
                    </Grid>
                :
                    <></>
                }
                {!loading && data.status===CourseStatus.InProgress ?
                    <Grid item xs={12} alignItems={'center'}>
                        <Box display="flex" justifyContent="center">
                            <CourseProgress width='100%' data={data}></CourseProgress>
                        </Box>
                    </Grid>
                :
                    <></>
                }
                <Grid item>    
                    {!loading ?
                        data.status ?
                        expired ?
                        
                        (
                          <Button size="small" disabled variant={"contained"}>
                            {data.status===CourseStatus.Completed?"Completado":"Expirado"}
                           
                          </Button>
                        ) : (
                          <CourseLimitHours courseId={data.courseId} status={data.status}   hours_limit={data.hours_limit} hours={data.hours} />
                        )
                        :
                        process.env.REACT_APP_SUBSCRIBE_ENABLED==='TRUE' ?
                        <Button size='small' variant="contained" component={Link} to={"/payment/course/" + data.courseId}>Inscríbete por USD${data.listPrice}.-</Button> 
                : null :
                        <Skeleton><Button/></Skeleton>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5' align='center'>{!loading ? `Contenidos del Curso` : <Skeleton/> }</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={6} lg={6}>
                {!loading ?  
                    <Box>
                        {data.chapters.map( (chapter:any, i:number) => { return (
                            <Grid container direction={'column'} key={i}>
                                <Typography sx={{fontWeight: 'bold'}} textAlign={'center'}>{chapter.name}: {chapter.description}</Typography>
                                    <List component="nav">
                                        {chapter.contents.map( (content:any, index:number, row:any) => { return (
                                            <ListItemButton key={index} divider={(index+1===row.length?false:true)}>
                                                {getIcon(content.type,28)}
                                                <ListItem alignItems='center'>
                                                    <Typography width='100%' textAlign={'center'}>{content.name}</Typography>
                                                </ListItem>

                                                {/* <Button component={Link} to={"/learn/"+course+"/"+content.contentId}>Iniciar</Button>       */}
                                            </ListItemButton>
                                        )})}
                                    </List>
                            </Grid>
                        )})}
                    </Box>
                :   <Skeleton/>
                }
                </Grid>
            </Grid>
        </Container>
    );
}