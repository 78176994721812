import { Card, CardActionArea, CardContent, Typography, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
export default function HelpPage() {
    const navigate = useNavigate()
    const handleLocation =(type:string)=>{
        let path = `/help/`+type 
        navigate(path);
    }
    
    return (
        <Container className='centered'>
            <Grid container spacing={2} justifyContent="center" pb={5}>
                <Grid item>
                    <Typography variant='h4' alignItems='center'>Página de ayuda</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" pb={5}>
                <Grid item xs={6} md={4}>
                    <Card sx={{ height: '100%', width: '100%' }}>
                    <CardActionArea onClick={()=>{handleLocation("Functionality")}}>
                            <CardContent>
                                <Typography variant="h6" component="div" align='center'>
                                    Uso de la página
                                </Typography>
                                <Typography variant="body2" align='center'>
                                    Resumen 
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Card sx={{ height: '100%', width: '100%' }}>
                    <CardActionArea onClick={()=>{handleLocation("Account")}}>
                            <CardContent>
                                <Typography variant="h6" component="div" align='center'>
                                    Cuenta
                                </Typography>
                                <Typography variant="body2" align='center'>
                                    Resumen 
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" pb={5}>
            <Grid item xs={6} md={4}>
                    <Card sx={{ height: '100%', width: '100%' }}>
                    <CardActionArea onClick={()=>{handleLocation("Certificate")}}>
                            <CardContent>
                                <Typography variant="h6" component="div" align='center'>
                                    Certificado
                                </Typography>
                                <Typography variant="body2" align='center'>
                                    Resumen 
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>

                </Grid>
                <Grid item xs={6} md={4}>
                    <Card sx={{ height: '100%', width: '100%' }}>
                    <CardActionArea onClick={()=>{handleLocation("Frecuent")}}>
                            <CardContent>
                                <Typography variant="h6" component="div" align='center'>
                                    Preguntas frecuentes
                                </Typography>
                                <Typography variant="body2" align='center'>
                                    Resumen 
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" pb={5}>
            <Grid item xs={6} md={4}>
                    <Card sx={{ height: '100%', width: '100%' }}>
                    <CardActionArea onClick={()=>{handleLocation("Question")}}>
                            <CardContent>
                                <Typography variant="h6" component="div" align='center'>
                                    QuestionLesson
                                </Typography>
                                <Typography variant="body2" align='center'>
                                    Resumen 
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>

                </Grid>
                <Grid item xs={6} md={4}>
                    <Card sx={{ height: '100%', width: '100%' }}>
                    <CardActionArea onClick={()=>{handleLocation("JupyterLesson")}}>
                            <CardContent>
                                <Typography variant="h6" component="div" align='center'>
                                    JupyterLessons
                                </Typography>
                                <Typography variant="body2" align='center'>
                                    Resumen 
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}