import { createSlice } from '@reduxjs/toolkit'

  export interface SessionState {
    isFilming: boolean[]
  }

  const initialState: SessionState = {
    isFilming: [],
  }

  export const sessionRewindSlice = createSlice({
    name: 'sessionRewind',
    initialState,
    reducers: {
      initFilm: (state) => {
        state.isFilming.push(true) 
        
      },
      closeFilm: (state) => {
        state.isFilming.pop()
      },

    },
  })

  export const { initFilm, closeFilm } = sessionRewindSlice.actions

  export default sessionRewindSlice.reducer