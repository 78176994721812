import React, {useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

type Props = {trigger:number, message:string}

const MessageDialog : React.FC<Props> = ({trigger,message}) => {
//const MessageDialog = ({ trigger:any }) => {
    const [open, setOpen] = React.useState(false);
 
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (trigger) {
            handleClickOpen();
        }
      }, [trigger]);

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      > <DialogTitle id="alert-dialog-title">
          {"Mensaje"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
            
          </DialogContentText>
   
          
        </DialogContent>
      </Dialog>
  );
}
export default MessageDialog;
