
import Typography from '@mui/material/Typography';
import { ContentStatus } from '../enums/programs.enums';
import { ListItem } from '@mui/material';
import { getIcon } from './Icons';
export function ListContents(props: any) {
    return (
        <ListItem
            sx={{
                height: 35,
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                paddingLeft: '8px',
            }}
            onClick={() => props.handleClick(props.indexChapter, props.indexContent, props.content.contentId)}
            ref={props.contentRef}>
            {getIcon(props.content.type,undefined,(props.activeItem === `${props.indexChapter}-${props.indexContent}` ? true : false), props.content.status)}
            <Typography variant='body2'
                sx={{ marginLeft: 1 }}
                color={props.content.status === ContentStatus.Completed || props.content.status === ContentStatus.Perfect ? 'black' : 'grey'}>
                {props.openDelay ? props.content.name : null}
            </Typography>
        </ListItem>
    )
}