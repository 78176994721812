import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { timeRemaining } from "../common/HoursLeft";

type Props={
start_date:Date,
course_hours:number,
isLearn:boolean,
setCourseTimeRemaining:Function,

}
export function CourseCountdown({course_hours,start_date,isLearn,setCourseTimeRemaining}:Props) {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(999999);
  const [redirectCountdown, setRedirectCountdown] = useState<number>(10);
  const [open, setOpen] = useState<boolean>(false);
  
  useEffect(() => {
    
    setCountdown(Math.max(0,timeRemaining(start_date.toString(),course_hours)));
    
  }, [course_hours,start_date]);
  useEffect(() => {
    setInterval(() => {
      setCountdown((prevCountdown) => Math.max(0, prevCountdown - 1));
    }, 1000);
  }, []);
   useEffect(()=>{
    if(countdown===0&&start_date!==null){
      setCourseTimeRemaining(0)
      if(isLearn){
        setOpen(true)   
        setInterval(() => {
            setRedirectCountdown((prevCountdown) => Math.max(0, prevCountdown - 1));
        }, 1000);   
      }
    }
  },[countdown,isLearn,start_date,setCourseTimeRemaining])
  useEffect(() => {
    if (redirectCountdown === 0) {
      setOpen(false);
      navigate("/course");
    }
  }, [redirectCountdown, navigate]);

  const hours = Math.floor(countdown / 3600);
  const minutes = Math.floor((countdown % 3600) / 60);
  const seconds = countdown % 60;
  return (
    <Grid container>
     <Grid item xs={12}>
     <Typography variant="body1" ml={1} align="center" color="black">
        Tiempo Restante:
      </Typography>
     </Grid>
      <Grid item xs={12}>
      <Typography variant="body1" ml={1} align="center" color="black">
        <span style={{ fontWeight: "bold" }}>
          {String(hours).padStart(2, "0")}:{String(minutes).padStart(2, "0")}:
          {String(seconds).padStart(2, "0")}
        </span>
      </Typography>
      </Grid>
     
      {
        <Dialog open={open} fullWidth>
          <DialogTitle sx={{   display: "flex",   justifyContent: "center",   alignContent: "center", }}
          >
            TIEMPO TERMINADO
          </DialogTitle>
          <DialogContent sx={{   display: "flex",   justifyContent: "center",   alignContent: "center", }}
          >
            Usted será redirigido al listado de sus cursos en:{" "}
            {redirectCountdown}
          </DialogContent>
          <DialogContent sx={{   display: "flex",   justifyContent: "center",   alignContent: "center", }}
          >
            <Button component={Link} to={"/course"} variant="contained">
              Regresar
            </Button>
          </DialogContent>
        </Dialog>
      }
    </Grid>
  );
}
