import { Grid } from "@mui/material";

export function ListSeparator(){

    return(
        <Grid sx={{
            borderLeft: "1px solid #38546d",
            background: "#16222c",
            borderColor: "#BBBBBB",
            width: "0.5px",
            height: "25px",
            right: "250px",
            top: "10px",
            marginLeft: "21px"
          }} ></Grid>
    )
}