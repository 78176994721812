

import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { Fab, Box,} from '@mui/material';
import{ useState } from 'react';
import { CommentDialog } from './CommentDialog';



export function CommentButton(props: any) {
    const [open, setOpen] = useState<boolean>(false)
   
    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen= ()=>{
        setOpen(true)
    }

    const fabStyle= {
        position: "absolute", 
        right: 5,
        bottom:25,
      };

    return (
        <>
            <Box  sx={{marginTop:0.5}}>
          
                <Fab onClick={handleOpen} /* onClick={handleOpen} */ aria-label="add" variant='extended' size="small" color="primary" sx={fabStyle} > <InsertCommentIcon /></Fab>
            </Box>
            <CommentDialog open={open} onClose={handleClose} showAll={true} contentId={props.contentId}></CommentDialog> 
           
        </>
    )
}