import { Badge, Box, IconButton, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { ContentStatus } from "../enums/programs.enums";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { PerfectIcon } from "./Icons";

type Props = {
    data: any,
    actualPage: number,
    actualLastPage: number,
    moveButtonPagination: Function,
    moveArrowPagination: Function
};

export function JupyterPagination({ data, actualPage, actualLastPage, moveArrowPagination, moveButtonPagination }: Props) {

    const [disabledPages, setDisabledPages] = useState<boolean[]>([])
    //create array to know which pages needs to be disabled to not move foward 
    useEffect(() => {
        let disabledArray: boolean[] = []
        data.forEach((jupyterlesson: any, index: number) => {
            if (index + 1 <= actualLastPage) {
                disabledArray.push(false)
            } else {
                disabledArray.push(true)
            }
        })
        setDisabledPages(disabledArray);
    }, [data, actualLastPage])


    function showPage(pageNumber: number, isPerfect: boolean = false) {
        return (
            <>
                <IconButton  sx={{ padding: 0}} disabled={disabledPages[pageNumber - 1]} onClick={() => { moveButtonPagination(pageNumber) }}>
                    <Typography sx={{
                        borderRadius: '50%',
                        backgroundColor: pageNumber === actualPage ? '#1976D2' : 'lightgray',
                        color: pageNumber === actualPage ? 'white' : disabledPages[pageNumber - 1] ? 'grey' : 'black',
                        fontSize: 'medium',
                        width: '24px',
                        height: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px',

                    }}>
                        {pageNumber}
                    </Typography>
                </IconButton>
                {isPerfect ?
                    <PerfectIcon size={15} right={-7} bottom={-3} />
                    :
                    null}

            </>

        );
    }
  
    return (

        <><Box sx={{alignContent:"center"}}>
            <IconButton disabled={actualPage === 1} sx={{ p: 0.5,mr:1 }} onClick={() => { moveArrowPagination(false) }} >
                <ChevronLeftIcon />
            </IconButton>
            {data.map((jupyterLesson: any, index: number) => {
                const pageIndex = index + 1; // Page number is 1-indexed
                return (
                    <Fragment key={index}    >
                        
                        {jupyterLesson.status === ContentStatus.Perfect ?
                            <>
                                <Badge sx={{mr:1}} badgeContent={<CheckIcon sx={{ fontSize: 10, padding: 0, color: 'white'}} />} color="success">
                                    {showPage(pageIndex, true)}
                                </Badge>
                            </>
                            :
                            jupyterLesson.status === ContentStatus.Completed ? (
                                <>
                                    <Badge sx={{mr:1}} badgeContent={<CheckIcon  sx={{ fontSize: 10, padding: 0, color: 'white',}} />} color="success">
                                        {showPage(pageIndex)}
                                    </Badge>
                                </>
                            ) : jupyterLesson.status === ContentStatus.Failed ? (
                                <Badge sx={{mr:1}} badgeContent={<CloseIcon style={{ fontSize: 10, padding: 0, color: 'white' }} />} color="error">
                                    {showPage(pageIndex)}
                                </Badge>
                            ) : (
                                <Badge hidden sx={{mr:1}}>{showPage(pageIndex)}</Badge>
                            )}

                    </Fragment>
                );
            })}
            <IconButton  disabled={actualPage === data.length || actualPage === actualLastPage} sx={{ padding: 0.5}} onClick={() => { moveArrowPagination(true) }}>
                <ChevronRightIcon />
            </IconButton>
            </Box>
        </>

    );
}
