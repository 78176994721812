import React, { useContext, useEffect } from 'react';
import { myContext } from '../contexts/Context';

export default function HomePage () {

    const ctx = useContext(myContext);
    useEffect(() => {
        if(!ctx || !ctx.user)
            window.location.href = "/login"
        else 
            window.location.href = "/myhome"
    },[ctx]);

    return (
        <div className="centered">
            {/* Bienvenido a DetaCode, tu plataforma de aprendizaje. */}
        </div>

    )
}