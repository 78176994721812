
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Fragment } from 'react';

type Props = {
    isWindowReponsesOpen: boolean,
    tests: any,
 

}

export function TestWindowPhone({ isWindowReponsesOpen, tests }: Props) {
 
    return (
        <>
            {isWindowReponsesOpen ?
                <Grid container marginTop={2}>
                    <Grid item sm={12} xs={12} >
                        <Box
                            sx={{
                                width: "100%",
                                overflow: "auto",
                                height: "100px",
                                border:"solid",
                                borderWidth:0.1,
                                
                                
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: "#e0e0e0",
                                    
                                }}
                            >
                                <Typography sx={{ marginLeft: 1 }}
                                    variant='body1'>Resultados de Tests</Typography>
                            </Box>
                            <Box sx={{  overflow: "auto" }}>

                                <Grid container >
                                { tests.map((test: any, index: number) => (
                                        <Fragment key={index}>
                                           
                                            <Grid item xs={12}>
                                            <Typography variant='body1' marginBottom={1}>
                                                Test {index+1}: {/* <strong>{test.output}</strong> */} {test.result===1?
                                                <>
                                              <CheckCircleIcon color='success' fontSize='medium' sx={{ verticalAlign: "-7px" }} />
                                                {test.expected_output? <><strong>{"Input: "}</strong>{test.input}<strong>{ "\n Output: "}</strong>{test.output}</>:""}
                                                </>
                                               
                                                :
                                                test.result===0?
                                
                                                <> <CancelIcon color='error' fontSize='medium' sx={{ verticalAlign: "-7px" }} />
                                                 
                                                 {
                                                   test.expected_output? <><strong>{"Input: "}</strong>{test.input}
                                                   </>:""}

                                                   {
                                                    test.expected_output?
                                                    test.error? 
                                                    <><strong>{ "\n Output: "}</strong>{test.error}</>
                                                    
                                                    :
                                                    <><strong>{ "\n Output: "}</strong>{test.output}</>
                                                    :
                                                    ""
                                                }
                                                  </>
                                               
                         
                                                :
                                                null}
                                              
                                            </Typography>                      
                                            </Grid>
                                                                                        
                                                                
                                        </Fragment>
                                    ))}
                                </Grid>
                            </Box>

                        </Box>

                    </Grid>

                </Grid>
                : null}
        </>
    )
}   