import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './slices/counterSlice'
import activeCourseReducer from './slices/activeCourseSlice'
import courseSession from './slices/courseSession'
import notificationSlice from './slices/notificationSlice'
import sessionRewindSlice from './slices/sessionRewindSlice'
import alertSlice from './slices/alertSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    activeCourse: activeCourseReducer,
    courseSession:courseSession,
    notifications:notificationSlice,
    sessionRewind:sessionRewindSlice,
    alert:alertSlice
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch