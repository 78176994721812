

import { Typography, Dialog, DialogTitle, DialogActions, Button, List, Box } from '@mui/material';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { Fragment, useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import { Chat } from './Chat';
import { CourseStatus } from '../enums/programs.enums';
import { RootState } from '../stores/store';
import * as DOMPurify from 'dompurify';
export function CommentDialog(props: any) {
    /*  const [open, setOpen] = useState<boolean>(false) */
    const [comments, setComments] = useState<any[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)
    const courseState = useSelector((state: RootState) => state.activeCourse);
    const cleanData = (html: string) => {
        return DOMPurify.sanitize(html)
    }
    useEffect(() => {
        const token = localStorage.getItem('token');
        MetadataApi.get(`LearnComments/getcommentsbycontentid/` + props.contentId, {
            headers: ({
                Authorization: 'Bearer ' + token
            }),
        }).then((res: AxiosResponse) => {
            setComments(res.data)
            setLoaded(true)
        })

    }, [props.contentId])


    return (
        <>
            {loaded ?
                <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
                    <Box >
                        {courseState.course.status === CourseStatus.Completed && !props.showAll ?
                            <DialogTitle>Felicidades, has finalizado el proyecto y obtenido tu certificado</DialogTitle>
                            :
                            null
                        }
                        {props.showAll ? <>
                            <DialogTitle>Historial de Comentarios:</DialogTitle>
                            <Box sx={{ backgroundColor: "#EAEAEA", height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
                                <Box sx={{ width: '100%', height: "300px", overflow: "auto" }}>
                                    <List sx={{ width: '100%', bgcolor: '#EAEAEA' }}>
                                        {comments.map((comment: any, index: number) => {
                                            return (
                                                <Fragment key={index}>
                                                    <Chat index={index} comment={comment}></Chat>
                                                </Fragment>
                                            )})
                                        }
                                    </List>
                                </Box>
                            </Box>
                        </>
                            :
                            comments[comments.length - 1] ?
                                <>
                                    <DialogTitle>Último Comentario:</DialogTitle>
                                    <Box sx={{ pl: 3, pr: 3 }}>
                                        <Typography variant="body1" color="text.primary" dangerouslySetInnerHTML={{ __html: cleanData(comments[comments.length - 1].comment) }} >
                                        </Typography>
                                    </Box>
                                </> : null
                        }
                        <DialogActions>
                            <Button onClick={props.onClose}>Cerrar</Button>
                        </DialogActions>
                    </Box>

                </Dialog>
                : null}

        </>
    )
}