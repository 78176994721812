import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CourseState {
  course: any,
  content: any,
  locationchapter: number,
  locationcontent: number,
}
const initialState: CourseState = {
  course:null,
  content: null,
  locationchapter:0,
  locationcontent:0,
}
function setContentById(course:any,contentId:number):any{
  if (!course) return {content:null,locationchapter:0,locationcontent:0};
  if (!contentId) {
    return {content:course.chapters[0].contents[0],locationchapter:0,locationcontent:0};
  }
  for (var i = 0; i < course.chapters.length; ++i) {
    for (var j = 0; j < course.chapters[i].contents.length; ++j) {
      if (course.chapters[i].contents[j].contentId === +contentId) {
        return {content:course.chapters[i].contents[j],locationchapter:i,locationcontent:j};
      }
    }
  } 
  return {content:course.chapters[0].contents[0],locationchapter:0,locationcontent:0};
}

export const activeCourseSlice = createSlice({
  name: 'activeCourse',
  initialState,
  reducers: {
    setCourse: (state, action: PayloadAction<any>) => {
      const {course,contentId} = action.payload
      state.course = course;
      const {content, locationchapter, locationcontent} = setContentById(course,+contentId);
      state.content=content;
      state.locationchapter=locationchapter;
      state.locationcontent=locationcontent;
    },
    setCourseStatus: (state, action: PayloadAction<string>) => {
      state.course.status=action.payload;
    },
    setCourseCertificate: (state, action: PayloadAction<string>) => {
      state.course.certificate={code:action.payload};
    },
    setCourseIsPerfect: (state, action: PayloadAction<boolean>) => {
      state.course.isPerfect=action.payload;
    },
    setContent: (state, action: PayloadAction<string>) => {
      const {content, locationchapter, locationcontent} = setContentById(state.course,+action.payload);
      state.content=content;
      state.locationchapter=locationchapter;
      state.locationcontent=locationcontent;
    },
    setContentStatus: (state, action: PayloadAction<string>) => {
      state.content.status=action.payload;
      state.course.chapters[state.locationchapter].contents[state.locationcontent].status=action.payload;

    },
    nextContent: (state) => {
      if (state.course.chapters[state.locationchapter].contents[state.locationcontent+1]){
        //console.log('existe');
        state.content=state.course.chapters[state.locationchapter].contents[state.locationcontent+1];
        state.locationcontent=state.locationcontent+1;
      }
      else if (state.course.chapters[state.locationchapter+1].contents[0]){
        //console.log('existe en otro capítulo');
        state.content=state.course.chapters[state.locationchapter+1].contents[0];
        state.locationchapter=state.locationchapter+1;
        state.locationcontent=0;
      }
      else {
        console.log('error: no existe contenido')
      }
    },
    previousContent: (state) => {
      if(state.locationcontent>0){
        //console.log('existe');
        state.content=state.course.chapters[state.locationchapter].contents[state.locationcontent-1];
        state.locationcontent=state.locationcontent-1;
      }
      else if(state.locationchapter>0){
        //console.log('existe en otro capitulo');
        const len = state.course.chapters[state.locationchapter-1].contents.length
        state.content=state.course.chapters[state.locationchapter-1].contents[len-1];
        state.locationchapter=state.locationchapter-1;
        state.locationcontent=len-1;
      }
      else {
        console.log('error: no existe contenido')
      }
    },
  },
})

export const { 
  setCourse,
  setCourseStatus,
  setCourseCertificate,
  setContent,
  setContentStatus,
  nextContent,
  previousContent,
  setCourseIsPerfect
} = activeCourseSlice.actions
export default activeCourseSlice.reducer