import React, { useContext, useEffect, useState } from 'react';
import { Card, CardActions, Typography, Button, Chip, Grid, Tooltip } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea/CardActionArea';
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/system';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import styled from '@emotion/styled';
import Collapse from '@mui/material/Collapse';
import CourseDetail from './CourseDetail';
import { Link } from 'react-router-dom';
import { myContext } from '../contexts/Context';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { CardSkeleton } from './CardSkeleton';
import { CourseStatus } from '../enums/programs.enums';
import { CertificateIcon } from './Icons';
import { CourseProgress } from './CourseProgress';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { CourseLimitHours } from "./CourseLimitHours";
import { timeRemaining } from '../common/HoursLeft';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}
const ExpandMoreElement = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    // transition: theme.transitions.create('transform', {
    //   duration: theme.transitions.duration.shortest,
    // }),
}));

type Props = {
    courseId?: any
    percentage?: number,
    expandable?: boolean,
    optional?: boolean
}

const CourseCard: React.FC<Props> = ({ courseId, percentage, expandable, optional }) => {
    const ctx = useContext(myContext);
    const [expired, setExpired] = useState<boolean>(false);

    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        if (ctx.loading) return;
        setLoading(true);

        if (ctx && ctx.user) {
            const token = localStorage.getItem('token');

            //validate if user have the course
            MetadataApi.post(`learncourses/validatecourse`,
                { courseId: courseId, username: ctx.user.username },
                { headers: ({ Authorization: 'Bearer ' + token }) }
            ).then((res: AxiosResponse) => {
                //console.log('validatecourse', res.data)
                if (!res.data) {
                    //if not, then we get general course data
                    MetadataApi.get(`courses/getcourse/${courseId}/data`, {
                    }).then((res: AxiosResponse) => {
                        //console.log('getcourse',res.data)
                        setData(res.data)
                        setLoading(false)
                    }, () => {
                        window.location.href = "/"
                    })
                } else if (ctx.user) {
                    //if user have the course, ask for user details
                    MetadataApi.post(`learncourses/getcourse`, {
                        courseId: courseId,
                        username: ctx.user.username
                    }, {
                        headers: ({
                            Authorization: 'Bearer ' + token
                        }),
                    }).then((res: AxiosResponse) => {
                       
                        //console.log('getcourse_learn:',res.data)
                         
                        if (res.data.hours_limit&&res.data.start_date!==null) {
                            const secondsRemaining=timeRemaining(res.data.start_date,res.data.hours)
                            if(secondsRemaining<=0){
                                setExpired(true)
                            }
                        }
                        setData(res.data);
                        setLoading(false);
                      },
                     () => {
                        window.location.href = "/"
                    })
                }
            })

        } else {
            MetadataApi.get(`courses/getcourse/${courseId}/data`, {
            }).then((res: AxiosResponse) => {
                //console.log('getcourse',res.data)
                setData(res.data)
                setLoading(false)
            }, () => {
                window.location.href = "/"
            })
        }
    }, [ctx, courseId])

    return (
        <>
            {!loading ?
               <Card
               sx={{ position: "relative", height: "100%", border: expandable ? "1px solid " + theme.palette.primary.main : "",}}>
                    <CardActionArea sx={{ height: '100%' }} component={Link} to={"/course/" + data.courseId}>
                    {data.hours_limit ? (
                      <Tooltip
                      color="primary" title="Curso con tiempo límite" sx={{ position: "absolute", top: 5, right: 5 }} >
                        <AccessTimeIcon />
                      </Tooltip>
                    ) : null}
                        <Grid sx={{ height: '100%', pl: 2, pr: 2, pt: 1, pb: 0 }} container direction={'row'} justifyContent={'space-between'} >
                            <Grid item xs={12}>
                                <Typography variant="h6" component="div">
                                    {data.name} {(optional ? "(Opcional)" : "")}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {data.description}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} marginTop={'auto'}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                                    {(data.type).split(',').map((value: string) => (
                                        <Chip key={value} label={value} size="small" />
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                    <CardActions disableSpacing>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems={'flex-end'}
                            spacing={2}
                        >
                            {expandable
                                ? <Button size="small" onClick={handleExpandClick}>Contenido</Button>
                                : <Button size="small" component={Link} to={"/course/" + data.courseId}>Saber más</Button>
                            }
                            {data && data.status ?      
                                expired ? (
                                  <Button size="small" disabled variant={"contained"}>
                                  {data.status===CourseStatus.Completed?"Completado":"Expirado"}
                                  </Button>
                                ) : (
                                <CourseLimitHours courseId={data.courseId} status={data.status} hours_limit={data.hours_limit} hours={data.hours}/>
                                )
                                : 
                                process.env.REACT_APP_SUBSCRIBE_ENABLED==='TRUE' ?
                                <Button size='small' variant="contained" component={Link} to={"/payment/course/" + data.courseId}>Inscríbete por USD${data.listPrice}.-</Button> 
                        : null }
             
                            
                            
                                {data.status===CourseStatus.InProgress?
                                <CourseProgress data={data}></CourseProgress>  
                            :null}
                                
                                {data.certificate && data.certificate.code
                                ? <CertificateIcon url={data.certificate.code} />
                                : <></>
                            }
                        </Stack>
                        {expandable ?
                            <ExpandMoreElement
                                expand={expanded}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMore />
                            </ExpandMoreElement>
                            : null}
                    </CardActions>
                    {expandable ?
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CourseDetail course={courseId} chapters={data.chapters} />
                        </Collapse>
                        : null}
                </Card>
                :
                <CardSkeleton />
            }
        </>
    );
}

export default CourseCard;