import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box/Box';
import {  Drawer, Grid } from '@mui/material';
import PythonLesson from '../components/PythonLesson';
import { useParams } from 'react-router-dom';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { ContentType } from '../enums/programs.enums';
import QuestionLesson from '../components/QuestionLesson';
import LearnDrawer from '../components/LearnDrawer';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import { setContent, setCourse } from '../stores/slices/activeCourseSlice';

import { myContext } from '../contexts/Context';
import JupyterLesson from '../components/JupyterLesson';
import ReadingLesson from '../components/ReadingLesson';
import { initSession } from '../common/SessionRewindMethods';
import { initFilm } from '../stores/slices/sessionRewindSlice';

export default function Learn() {
  const ctx = useContext(myContext);
  const { id, contentId } = useParams();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingContent, setLoadingContent] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const courseState = useSelector((state: RootState) => state.activeCourse);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!ctx || !ctx.user || ctx.loading) return;
    setLoading(true);
    setLoadingContent(true);
    const token = localStorage.getItem('token');

    MetadataApi.post(`learncourses/validatecourse`,
      { courseId: id, username: ctx.user.username },
      { headers: ({ Authorization: 'Bearer ' + token }) }
    ).then((res: AxiosResponse) => {
      //console.log('validatecourse', res.data)
      if (!res.data) return (<>Error</>);
      else {
        if (!ctx.user) return;
        MetadataApi.post(`learncourses/getcourse`,
          { courseId: id, username: ctx.user.username },
          { headers: ({ Authorization: 'Bearer ' + token }), }
        ).then((res: AxiosResponse) => {
          //console.log('getactivecourse', res.data)
          if(!res.data) return (<>Error</>);
          let data = {...res.data};     
          if (data.start_date === null && ctx.user) {
            MetadataApi.post(
              `learncourses/startcourse`,
              {
                courseId: id,
                username: ctx.user.username,
              },
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            ).then((res: AxiosResponse) => {
              if(res.data!==''){
                data={...data,start_date:res.data}
                dispatch(setCourse({course:data,contentId:contentId}));
                setLoading(false)
                setLoadingContent(false);
              }
            });
          }else{
            dispatch(setCourse({course:data,contentId:contentId}));
            setLoading(false)
            setLoadingContent(false);
          }
        })
      }
    })

  }, [dispatch,contentId,id,ctx])
  const [courseTimeRemaining,setCourseTimeRemaining]=useState<number>(9999999)
  const [expired,setExpired]=useState<boolean>(false)
  useEffect(()=>{
    if(courseTimeRemaining<=0)setExpired(true)
  },[courseTimeRemaining])
  useEffect(() => {
    setLoadingContent(true);
    if (contentId)
      dispatch(setContent(contentId));
    setLoadingContent(false);
  }, [dispatch, contentId])

  const renderLesson = () => {
    switch(courseState.content.type) {
      case ContentType.PythonLesson:  return <PythonLesson contentId={courseState.content.contentId}  />;
      case ContentType.JupyterLesson: return <JupyterLesson contentId={courseState.content.contentId} openDrawer={openDrawer} expired={expired} setCourseTimeRemaining={setCourseTimeRemaining} />;
      case ContentType.Questions:     return <QuestionLesson contentId={courseState.content.contentId} expired={expired} setCourseTimeRemaining={setCourseTimeRemaining} />;
      case ContentType.Reading:       return <ReadingLesson contentId={courseState.content.contentId} expired={expired} setCourseTimeRemaining={setCourseTimeRemaining} />;
      default: return <>Error en carga de contenido</>;
    }
  }


  useEffect(() => {

  }, [courseState.course])

  //session-rewind
  useEffect(() => {
    if (!ctx || !ctx.user || ctx.loading || !courseState.course || !ctx.user.username) return;
    if (!isMounted) {
      setIsMounted(true)
      if(courseState.course.hours_limit&& process.env.REACT_APP_SESSIONREWIND_ENABLED==="TRUE"){
        dispatch(initFilm())
        initSession(ctx.user.username,courseState.course.courseId)
      }

    }
 
  }, [id, ctx, courseState.course, isMounted,dispatch]);
  
  return (
    <Box sx={{ display: 'flex' }}>
      {!loading
        ? <LearnDrawer open={openDrawer} setOpen={setOpenDrawer} />
        : <Drawer variant="permanent" sx={{ width: 40 }}><Skeleton height={'100%'} width={40} /></Drawer>
      }
      {!loading && !loadingContent && courseState.content ? (
        renderLesson()
      ) : (
        <Grid container spacing={2} p={3}>
          <Grid item xs pr={2}><Skeleton height={300} /></Grid>
          <Grid item xs={12} md={6} border={1}><Skeleton height={300} /></Grid>
        </Grid>
      )}
     {/*  {!loading && !loadingContent && courseState.content
        ? <LearnBottomNavigator />
        : <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={1}>
            <Skeleton width='100%' height={70}/>
          </Paper>
      } */}

    </Box>
  )
}