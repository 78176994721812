import React from 'react';
import Stack from '@mui/material/Stack';
import CourseCard from './CourseCard';
import Grid from '@mui/material/Grid/Grid';
import { Avatar  } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Props = {courses:any}

const CourseList : React.FC<Props> = ({courses}) => {

  const theme = useTheme();

    return (
      <Stack>
        {courses.map((course:any,index:number) => {
          return(
              <Grid pb={2} container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1} key={index}>  
                  <Grid item xs={1}><Avatar  sx={{ bgcolor: theme.palette.primary.main }}>{index+1}</Avatar></Grid>
                  <Grid item xs={11}><CourseCard courseId={course.courseId} expandable optional={!course.obligatory}/></Grid>
              </Grid>
          )
        })}
      </Stack>
  );
}
export default CourseList;