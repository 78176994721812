import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { CertificateIcon, PerfectIcon } from './Icons';
import { Badge, Box, Grid, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { RootState } from '../stores/store';
type Props = { trigger: number, message: string, isPerfect: boolean }

const MessageDialogCertificate: React.FC<Props> = ({ trigger, message, isPerfect }) => {
    //const MessageDialog = ({ trigger:any }) => {
    const [open, setOpen] = useState(false);
    const courseState = useSelector((state: RootState) => state.activeCourse);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (trigger) {
            handleClickOpen();
        }
    }, [trigger]);
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        > <DialogTitle id="alert-dialog-title">
                {"Mensaje"}
            </DialogTitle>
            <DialogContent>
                <Box justifyContent={'center'} display="flex">
                    <Grid container justifyContent={'center'}
                    >
                        <Grid item xs={12} md={2} textAlign={'center'}>
                       
                        <Badge >
                        {courseState.course.certificate!==null?
                         <CertificateIcon size={50} url={courseState.course.certificate.code} />    
                        :null}
                       
                            {isPerfect ?
                            <PerfectIcon size={20} right={-2} bottom={-3}/>

                           : null}
                             </Badge>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Typography>
                                {message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
export default MessageDialogCertificate;
