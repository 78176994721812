import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import { AxiosResponse } from 'axios';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import MetadataApi from '../apis/metadata.api';
import { myContext } from '../contexts/Context';

export default function Payment () {
  const { type, id } = useParams();
  const ctx = useContext(myContext);

  const [loading, setLoading] = useState<boolean>(false);

  const handleExpandClickPay = () => {
    if(!ctx || !ctx.user || !type) return;
    setLoading(true);
    const token = localStorage.getItem('token');
    if(type==='program'){
      MetadataApi.post(`learnprograms/activateprogram`, {
        programId: id,
        username: ctx.user.username
      }, {
        headers: ({
          Authorization: 'Bearer ' + token
        }),
      }).then((res: AxiosResponse) => {
        //console.log('activatedprogram:', res.data)
        setLoading(false);
      })
    } else { //course
      MetadataApi.post(`learncourses/activatecourse`, {
        courseId: id,
        username: ctx.user.username
      }, {
        headers: ({
          Authorization: 'Bearer ' + token
        }),
      }).then((res: AxiosResponse) => {
        //console.log('activatedcourse:', res.data)
        setLoading(false);
      })
    }
    
  };
  const handleExpandClick = () => {
    window.location.href = "/"+type+"/"+id
  };

  return (
    <Container className="centered" maxWidth="xs">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} justifyContent="center">
            <Typography variant='h4' align='center'>Pago de {type==='program'?"programa":"curso"} {id}</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleExpandClickPay} disabled={loading}>{loading?'Procesando':'Pagar'}</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleExpandClick}>Ir a {type==='program'?"programa":"curso"}</Button>
          </Grid>
        </Grid>
        <br/>
        <br/>
        
    </Container>
  )
}