import React, { useContext, useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Typography, Button } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea/CardActionArea';
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { myContext } from '../contexts/Context';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { ProgramStatus } from '../enums/programs.enums';
import { CertificateIcon } from './Icons';
import { CardSkeleton } from './CardSkeleton';
import { ProgramProgress } from './ProgramProgress';

type Props = {
    programId?:any
}

const ProgramCard: React.FC<Props> = ({ programId }) => {
    const ctx = useContext(myContext);

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
       
        //setLoadingProgramStatus(true);
        if (ctx.loading) return;
        setLoading(true);
        if (ctx && ctx.user) {
            const token = localStorage.getItem('token');
            //validate if user have the program
            MetadataApi.post(`learnprograms/validateprogram`,
                { programId: programId, username: ctx.user.username },
                { headers: ({ Authorization: 'Bearer ' + token }) }
            ).then((res: AxiosResponse) => {
                
                //console.log('validateprogram', res.data)
                if (!res.data) {
                    //if not, then we get general program data
                    MetadataApi.get(`programs/getprogram/${programId}/data`, {
                    }).then((res: AxiosResponse) => {
                        //console.log('getprogram',res.data)
                        setData(res.data)
                        setLoading(false)
                    }, () => {
                        window.location.href = "/"
                    })
                } else if (ctx.user) {
                    //if user have the program, ask for user details
                    MetadataApi.post(`learnprograms/getprogram`, {
                        programId: programId,
                        username: ctx.user.username
                    }, {
                        headers: ({
                            Authorization: 'Bearer ' + token
                        }),
                    }).then((res: AxiosResponse) => {
                        //console.log('getprogram_learn:',res.data)
                        setData(res.data)
                        setLoading(false)
                    }, () => {
                        window.location.href = "/"
                    })
                }
            })

        } else {
            MetadataApi.get(`programs/getprogram/${programId}/data`, {
            }).then((res: AxiosResponse) => {
                //console.log('getprogram',res.data)
                setData(res.data)
                setLoading(false)
            }, () => {
                window.location.href = "/"
            })
        }
    }, [ctx, programId]);

    return (
        <>
        {!loading?
        <Card sx={{ height: '100%' }}>
        <CardActionArea component={Link} to={"/program/" + data.programId}>
            <CardContent>
                <Typography variant="h6" component="div">
                    Programa de {data.name}
                </Typography>
                <Typography variant="body2" >
                    {data.shortDescription}
                </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={'flex-end'}
                spacing={2}
            >
                <Button size="small" component={Link} to={"/program/" + data.programId}>Saber más</Button>
                {
                    data.status ?
                        <Button size='small' variant={data.status === ProgramStatus.Completed ? 'outlined' : 'contained'} component={Link} to={"/program/" + data.programId}>{data.status === ProgramStatus.ToDo ? 'Iniciar programa' : (data.status === ProgramStatus.InProgress ? 'Continuar programa' : 'Programa Completado')}</Button>
                        :
                        
                        process.env.REACT_APP_SUBSCRIBE_ENABLED==='TRUE' ?
                                <Button size='small' variant="contained" component={Link} to={"/payment/course/" + data.courseId}>Inscríbete por USD${data.listPrice}.-</Button> 
                        : null }
                {data.status===ProgramStatus.InProgress?
                                <ProgramProgress data={data}></ProgramProgress>
                            :null}
                {data.certificate && data.certificate.code
                    ? <CertificateIcon url={data.certificate.code} />
                    : <></>
                }
            </Stack>
        </CardActions>
    </Card>
        :
        <CardSkeleton />
        }
        </>
        
    );
}

export default ProgramCard;