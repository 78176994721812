import React, { useContext, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { Grid, Typography, IconButton } from '@mui/material';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { CardSkeleton } from '../components/CardSkeleton';
import CourseCard from '../components/CourseCard';
import CourseChip from '../components/CourseChip';
import { myContext } from '../contexts/Context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
export default function Course() {

    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [tagList, setTagList] = React.useState<string[]>([]);
    const [searchParams] = useSearchParams();

    const paramValue = searchParams.get('myhome');
    const ctx = useContext(myContext);
    const [title, setTitle] = useState<string>()
    const [goBack, setGoBack] = useState<boolean>(false)
    const navigate = useNavigate();
    const goLastPage = () => { navigate(-1); }
    useEffect(() => {
        if (paramValue === "mycourses") {
            setGoBack(true)
            setTitle("Todos mis Cursos")
            if (!ctx || !ctx.user) return;
            setLoading(true);
            const token = localStorage.getItem('token');
            MetadataApi.post("learncourses/getallcoursesbyuser", {
                username: ctx.user?.username
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            }).then((res: AxiosResponse) => {
                setData(res.data)
                setLoading(false)
            })
        } else {
            setGoBack(false)
            setTitle("¿Quieres aprender un tema en particular?")
            const token = localStorage.getItem('token');
            MetadataApi.get("courses/getallcourses", {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            }).then((res: AxiosResponse) => {
                setData(res.data)
                setLoading(false)
            })
        }
    }, [paramValue, ctx])


    return (
        <Container className='centered'>
            <Grid container spacing={2} justifyContent="center" pb={5}>
                {goBack ?
                    <Grid position={"absolute"} left={0} top={10}>
                        <IconButton onClick={goLastPage}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Grid>
                    : null}
                <Grid item xs={12}>
                    <Typography variant='h4' textAlign='center'>{title}</Typography>
                </Grid>
                {!goBack?<Grid item xs={12}>
                    <Typography variant='body1' textAlign='center'>Especialízate con uno de los cursos que tenemos para ti.</Typography>
                </Grid>:null}
                
                <Grid item mt={3} xs={11} sm={8} md={6} >
                    <CourseChip tagList={tagList} setTagList={setTagList} />
                </Grid>

            </Grid>
            {!loading ?
                <Grid container spacing={2}>
                    {data.map((item: any, index: number) => {
                        if (tagList.length === 0 || tagList.some((v: any) => (item.type).split(',').includes(v))) {
                            return (
                                <Grid key={index} item xs={12} md={6}>
                                    <CourseCard courseId={item.courseId} />
                                </Grid>
                            )
                        } else return '';
                    })
                    }
                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}><CardSkeleton /></Grid>
                    <Grid item xs={12} md={6}><CardSkeleton /></Grid>
                </Grid>

            }
        </Container>
    );
}