import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface CounterState {
  courses: any[]
}

const initialState: CounterState = {
  courses: [],
}

export const courseSession = createSlice({
  name: 'courseSession',
  initialState,
  reducers: {
    pushCourse:(state,action:PayloadAction<any>)=>{
        state.courses.push(action.payload)
    },
  
  },
})

export const {pushCourse } = courseSession.actions

export default courseSession.reducer