import React, { useEffect, useState, useContext } from 'react';
import Container from '@mui/material/Container';
import { Grid, IconButton, Typography } from '@mui/material';
import ProgramCard from '../components/ProgramCard';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { CardSkeleton } from '../components/CardSkeleton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { myContext } from '../contexts/Context';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
export default function Program() {
    const navigate = useNavigate();
    const goLastPage = () => { navigate(-1); }
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const [title, setTitle] = useState<string>()
    const [goBack, setGoBack] = useState<boolean>(false)
    const ctx = useContext(myContext);
    /* useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem('token');
        MetadataApi.get("programs/getallprograms", {
            headers: ({
            Authorization: 'Bearer ' + token
            })
        }).then((res : AxiosResponse) => {
            //console.log(res.data)
            setData(res.data)
            setLoading(false)
        }, () => {
            window.location.href = "/"
        })
    },[]); */
    const paramValue = searchParams.get('myhome');
    useEffect(() => {
        if (paramValue === "myprograms") {
            setGoBack(true)
            setTitle("Todos mis Programas")
            if (!ctx || !ctx.user) return;
            setLoading(true);
            const token = localStorage.getItem('token');
            MetadataApi.post("learnprograms/getallprogramsbyuser", {
                username: ctx.user?.username
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            }).then((res: AxiosResponse) => {
                setData(res.data)
                setLoading(false)
            })
        } else {
            setGoBack(false)
            setTitle("¡Incorpórate al mundo de los datos!")
            const token = localStorage.getItem('token');
            MetadataApi.get("programs/getallprograms", {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            }).then((res: AxiosResponse) => {
                setData(res.data)
                setLoading(false)
            })
        }
    }, [paramValue, ctx])

    return (
        <Container className='centered'>
            {/* <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                />
            </Search> */}
            <Grid container justifyContent="space-between" alignItems="center" alignContent="center">
                    <Grid container spacing={2} justifyContent="center" pb={5}>
                    
                    {goBack?
                        <Grid position={"absolute"} left={0} top={10}>
                        <IconButton onClick={goLastPage}>
                            <ChevronLeftIcon />
                        </IconButton>
                        </Grid>
                    :null}
               
                        <Grid item>
                            <Typography variant='h4' textAlign='center'>{title}</Typography>
                        </Grid>
                    {!goBack? <Grid item xs={12}>
                            <Typography variant='body1' textAlign='center'>La ciencia de datos ha quedado para quedarse. Súmate a través de los siguientes programas.</Typography>
                        </Grid>:null}
                        
                    </Grid>
             
            </Grid>

            {!loading ?
                <Grid container spacing={2}>
                    {data.map((item: any, index: number) => {
                        return (
                            <Grid key={index} item xs={12} md={6}>
                                <ProgramCard programId={item.programId} />
                            </Grid>
                        )
                    })
                    }
                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}><CardSkeleton /></Grid>
                    <Grid item xs={12} md={6}><CardSkeleton /></Grid>
                </Grid>

            }
        </Container>
    );
}