import React, { useEffect, useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { myContext } from '../contexts/Context';
import { Stack, Grid, Skeleton, Button } from '@mui/material';

type Props = { trigger: number, programId: number }

const ProjectSelectDialog: React.FC<Props> = ({ trigger, programId }) => {

  const ctx = useContext(myContext);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [clicked, setClicked] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!ctx || !ctx.user) return;
    if (trigger) {
      handleClickOpen();
      setLoading(true);
      const token = localStorage.getItem('token');
      MetadataApi.post(`learnprograms/getprogramprojects`,
        { programId: programId, username: ctx.user.username },
        { headers: ({ Authorization: 'Bearer ' + token }) }
      ).then((res: AxiosResponse) => {
        /* console.log('getprogramprojects',res.data) */
        setData(res.data)
        setLoading(false)
      }, () => {
        //window.location.href = "/"
      })
    }
  }, [trigger, ctx, programId]);

  const handleClick = (courseId:number) => {
    if (!ctx || !ctx.user) return;
    setClicked(true);
    const token = localStorage.getItem('token');
    MetadataApi.post(`learncourses/activatecourse`, {
      courseId: courseId,
      username: ctx.user.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      //console.log('activatedcourse:', res.data)
      handleClose();
      window.location.reload();
    })
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    > <DialogTitle id="alert-dialog-title">
        { !loading ? `Seleccione proyecto para programa` : "Cargando..." }
      </DialogTitle>
      <DialogContent>
          <Stack>
            { !loading && data ?
              data.map((course: any, index: number) => {
              return (
                <Grid pb={2} container direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1} key={index}>
                  <Grid item>{course.name}</Grid>
                  <Grid item><Button variant='contained' onClick={()=>handleClick(course.courseId)} disabled={clicked}>Seleccionar</Button></Grid>
                </Grid>
              )
              })
              : <><Skeleton height={40}/><Skeleton height={40}/><Skeleton height={40}/></>
          }
          </Stack>
      </DialogContent>
    </Dialog>
  );
}
export default ProjectSelectDialog;
