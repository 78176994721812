
import { Button, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MetadataApi from '../apis/metadata.api';
import { myContext } from '../contexts/Context';
import { ContentStatus } from '../enums/programs.enums';
import { setContentStatus, setCourseCertificate, setCourseStatus } from '../stores/slices/activeCourseSlice';
import { RootState } from '../stores/store';
import MessageDialog from './MessageDialog';

type Props = {contentId:number}

const PythonLesson : React.FC<Props> = ({contentId}) => {
  const ctx = useContext(myContext);
  const courseState = useSelector((state:RootState) => state.activeCourse);
  const dispatch = useDispatch();

  const [errorTrigger, setErrorTrigger] = useState(0);
  const [certTrigger, setCertTrigger] = useState(0);

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if(!ctx || !ctx.user || ctx.loading) return;
    setLoading(true);
    const token = localStorage.getItem('token');
    MetadataApi.post(`learncontents/getcontent`, { 
      contentId:contentId,
      username:ctx.user.username
    },{ headers: ({
      Authorization: 'Bearer ' + token
      }),
    }).then((res : AxiosResponse) => {
      // console.log('getcontent_learn:',res.data)
      setData(res.data)
      setLoading(false)
    }, () => {
      // window.location.href = "/"
    })
  }, [ctx,contentId])

  const handleClick = (type:string) => () => {
    //console.log(type);
    if(!ctx || !ctx.user) return;
    const token = localStorage.getItem('token');
    MetadataApi.post(`learncontents/savecontent`, { 
      contentId:contentId,
      username:ctx.user.username,
      status:type,
      courseId:courseState.course.courseId
    },{
      headers: ({
      Authorization: 'Bearer ' + token
      }),
    }).then((res : AxiosResponse) => {
      //console.log('savecontent coursestatus',res.data);
      dispatch(setContentStatus(type));
      dispatch(setCourseStatus(res.data.course.courseStatus));
      if(res.data.course.courseCertificate && res.data.course.courseCertificate!==''){
        dispatch(setCourseCertificate(res.data.course.courseCertificate));
        setCertTrigger((certTrigger) => certTrigger + 1);
      }
    }, () => {
      setErrorTrigger((errorTrigger) => errorTrigger + 1);
    })
  };

  if(!contentId || !ctx) return <>Error</>
  
  return (
      <>
      <MessageDialog trigger={errorTrigger} message={'Error al guardar en Base de Datos'}/>
      <MessageDialog  trigger={certTrigger} message={'Felicidades, has finalizado el curso y obtenido tu certificado'}/>
      {!loading ?
      <Grid container spacing={2} p={3}>
          <Grid item xs pr={2}>
            <Typography variant='h5'>{courseState.content.name}</Typography>
            <Typography>{data.code_lesson.detail}</Typography>
          </Grid>
          <Grid item xs={12} md={6} border={1}>
            <pre>{data.code_lesson.basecode}</pre>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{textAlign:'center'}}>
              <Button sx={{m:1}} variant='contained' size="small" onClick={handleClick(ContentStatus.InProgress)}>Marcar InProgress</Button>
              <Button sx={{m:1}} variant='contained' size="small" onClick={handleClick(ContentStatus.Failed)}>Marcar Failed</Button>
              <Button sx={{m:1}} variant='contained' size="small" onClick={handleClick(ContentStatus.Completed)}>Marcar Completed</Button>
              <Button sx={{m:1}} variant='contained' size="small" onClick={handleClick(ContentStatus.Perfect)}>Marcar Perfect</Button>
            </Box>
          </Grid>
      </Grid>
      :
      <Skeleton></Skeleton>
      }
      </>
  )
}

export default PythonLesson;