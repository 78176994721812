import React, { useEffect, useState, } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ContentStatus, CourseStatus } from '../enums/programs.enums';
import { Button, DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setContent, setCourseIsPerfect } from '../stores/slices/activeCourseSlice';
import { RootState } from '../stores/store';

type Props = {
  trigger: number,
  contentState: string,
  nextPage?: Function,
  lastLessonStatus?: string,
  score?:string
}

const MessageResultDialog: React.FC<Props> = ({ trigger, contentState, nextPage, lastLessonStatus,score }) => {
  //const MessageDialog = ({ trigger:any }) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [chapterLeftLocation, setChapterLeftLocation] = useState<number>(0)
  const [contentLeftLocation, setContentLeftLocation] = useState<number>(0)
  const courseState = useSelector((state: RootState) => state.activeCourse);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    if (trigger) {
      handleClickOpen();
    }
  }, [trigger]);

  const goNextPage = (CourseComplete: boolean) => {
    setOpen(false)
    if (nextPage) {
      setTimeout(() => {
        nextPage(CourseComplete)
      }, 100)
    }
  }

  useEffect(() => {
    let chapterLocation = courseState.locationchapter
    let contentLocation = courseState.locationcontent
    let findedIt = false
    for (let chapterCounter = 0; chapterCounter < courseState.course.chapters.length; chapterCounter++) {
      for (let contentCounter = 0; contentCounter < courseState.course.chapters[chapterLocation].contents.length; contentCounter++) {
        contentLocation = (contentLocation + 1) % courseState.course.chapters[chapterLocation].contents.length
        let contentStatus = courseState.course.chapters[chapterLocation].contents[contentLocation].status
        if (courseState.course.status === CourseStatus.Completed) {
          if (contentStatus === ContentStatus.Completed) {
            findedIt = true
            break
          }
        } else {
          if (contentStatus === ContentStatus.Failed || contentStatus === ContentStatus.InProgress || contentStatus === null || contentStatus === ContentStatus.ToDo) {
            findedIt = true
            break
          }
        }

      }
      if (findedIt) {
        setChapterLeftLocation(chapterLocation)
        setContentLeftLocation(contentLocation)

        break
      } else {
        contentLocation = -1
        chapterLocation = (chapterLocation + 1) % courseState.course.chapters.length
      }

    }
  }, [courseState])

  useEffect(() => {
    let perfectCoutenter = 0
    let contentCounter = 0
    courseState.course.chapters.forEach((chapter: any) => {
      chapter.contents.forEach((content: any, index: number) => {
        contentCounter++
        if (content.status === ContentStatus.Perfect) perfectCoutenter++
      })
    })
    let isPerfect = false
    if (perfectCoutenter === contentCounter) isPerfect = true
    dispatch(setCourseIsPerfect(isPerfect))

  }, [courseState.course.chapters, dispatch])
  const goNextUnfinishContent = () => {
    handleClose()
    dispatch(setContent(courseState.course.chapters[chapterLeftLocation].contents[contentLeftLocation].contentId))

  }
  const goFirstContent = () => {
    handleClose()
    dispatch(setContent(courseState.course.chapters[0].contents[0].contentId))

  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    > <DialogTitle id="alert-dialog-title">
        {"Resultado"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">

          {
            courseState.course.isPerfect ?
              "Curso Perfecto, ¿Desea volver al primer contenido?"
              :
              courseState.course.status === CourseStatus.Completed ?
                contentState === ContentStatus.Perfect ?//falta identificar si realmente se obtuvo un perfect o complete en el validate
                  "Contenido Perfecto, ¿Desea avanzar al siguiente contenido no perfecto?"
                  :
                  lastLessonStatus === ContentStatus.Perfect ?
                    "Ejercicio Perfecto,"+(score&&score!==""? score:"")+ " ¿Desea avanzar al siguiente ejercicio no perfecto?"
                    :
                    "Ejercicio No perfecto, intente nuevamente"
                :
                contentState === ContentStatus.Completed || contentState === ContentStatus.Perfect ?

                  "Lección completada "+(score&&score!==""? score:"")+" ¿Desea avanzar al siguiente contenido?"
                  :

                  lastLessonStatus === ContentStatus.Completed || lastLessonStatus === ContentStatus.Perfect ?
                    "Ejercicio " +(lastLessonStatus===ContentStatus.Completed?"Completado ": lastLessonStatus === ContentStatus.Perfect? "Perfecto ":"" )+(score&&score!==""? score:"")+" ¿Desea avanzar al siguiente ejercicio?"
                    :
                    "Ejercicio Fallado, intente nuevamente"

          }
        </DialogContentText>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Cerrar</Button>
          {
            courseState.course.isPerfect ?
              <Button variant='contained' onClick={goFirstContent}>Volver</Button>
              :

              courseState.course.status === CourseStatus.Completed ?
                contentState === ContentStatus.Perfect ?
                  <Button variant='contained' onClick={goNextUnfinishContent}>Siguiente</Button>
                  :
                  lastLessonStatus === ContentStatus.Perfect ?
                    <Button variant='contained' onClick={() => { goNextPage(true) }}>Siguiente</Button>
                    :
                    null
                :
                contentState === ContentStatus.Completed || contentState === ContentStatus.Perfect ?
                  <Button variant='contained' onClick={goNextUnfinishContent}>Siguiente</Button>
                  :
                  lastLessonStatus === ContentStatus.Completed || lastLessonStatus === ContentStatus.Perfect ?
                    <Button variant='contained' onClick={() => { goNextPage(false) }}>Siguiente</Button>
                    :
                    null
          }

        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
export default MessageResultDialog;
