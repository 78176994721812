import Card from '@mui/material/Card';
import { Typography, CardActionArea, CardActions, CardContent, Skeleton } from '@mui/material';
import React from 'react';

export function CardSkeleton () {
    return (
      <Card sx={{ height:'100%' }}>
      <CardActionArea>
          <CardContent>
              <Typography variant="h6" component="div">
                  <Skeleton/>
              </Typography>
              <Typography variant="body2" >
                <Skeleton/>
                <Skeleton/>
              </Typography>
          </CardContent>
      </CardActionArea>
      <CardActions >
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
      </CardActions>
  </Card>
  );
}
