import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { Link } from 'react-router-dom';

export default function Login () {
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    const [error, setError] = useState<string>("")

    const login = (event:any) => {
      event.preventDefault();
      setError('')
      MetadataApi.post("/auth/login", {
          username,
          password
      }, {
      }).then((res : AxiosResponse) => {
        //console.log('info login',res.data);
        localStorage.setItem('token', res.data.access_token);
        //navigator("/");
        window.location.href = "/myhome"
      }, () => {
        console.log("Login Failed");
        setError('error')
      })
    }

  return (

    <Container className='centered' maxWidth="xs">
          <Typography component="h1" variant="h5" align='center'>
            Inicio de sesión
          </Typography>
          <Box
            component="form"
            onSubmit={login}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={username.length===0 || password.length===0}
            >
              Ingresar
            </Button>
            {error!==""? (
              <Alert variant="filled" severity="error">
                Inicio de sesión fallido.
              </Alert>
            ):(<></>)}
            { process.env.REACT_APP_REGISTER_ENABLED==='TRUE' ?
              <>
                <Typography width='100%' textAlign={'center'}>ó</Typography>
                <Button
                  component={Link} to="/register"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Registrarse
                </Button>
              </>
              : <></>
            }
          </Box>
      </Container>
  )
}