export enum ChatStatus {
    Completed = 'Completed',
    Reviewing = 'Reviewing',
    Started ='Started',
  }

export enum ChatMessage{
  Endend="Chat Finalizado",
  Taked="Ha Llegado un Profesor",
  Reactivate="El Profesor a reactivado el Chat"
}