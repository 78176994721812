import { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { CourseStatus } from '../enums/programs.enums';
import { ProjectStatusEnum, projectStatus } from '../pages/ProgramDetail';

export function ProgramProgress(props: any) {
    const [progression, setProgression] = useState<number>(0);

    useEffect(() => {
        let courseCounter = 0
        let courseTotal = 0;//props.data.programcourses.length
        props.data.programcourses.map((programcourse: any) => {
            if (!programcourse.obligatory || !programcourse.course) return true;
            if (programcourse.course.status === CourseStatus.Completed)
                courseCounter++;
            courseTotal++;
            return true;
        })
        if(projectStatus(props.data)===ProjectStatusEnum.Selectable){
            courseTotal++;

        }
        //console.log('courseTotal',courseTotal,'courseCounter',courseCounter)
        setProgression(Math.round((100 / courseTotal) * courseCounter))
    }, [props.data])

    return (
        <Grid container display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'end'}
            sx={{ width: 100 }}>
            <Grid item sx={{ minWidth: 100 }}>
                <LinearProgress sx={{ height: 10, borderRadius: 5 }} variant="determinate" color='primary' value={progression} />
            </Grid>
            <Grid item sx={{ minWidth: 35 }}>
                <Typography variant="body2" align='center' color="text.secondary" sx={{ height: 15 }}>{`${progression}%`}</Typography>
            </Grid>
        </Grid>
    )
}