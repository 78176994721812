import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import { Box } from '@mui/system';
import { CertificateIcon } from './Icons';
import { ListStepper } from './ListStepper';
import { Badge, Tooltip } from '@mui/material';
import starImage from '../assets/star.png'
type Props = {
  open: boolean,
  setOpen: Function,
}

export default function LearnDrawer({ open, setOpen }: Props) {

  const theme = useTheme();
  const [openDelay, setOpenDelay] = useState(false);
  const alertState = useSelector((state: RootState) => state.alert);
  const handleDrawerOpen = () => {
    setOpen(true);
    setTimeout(() => { setOpenDelay(true); }, 300);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setOpenDelay(false);
  };
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
  const openedMixin = (theme: Theme): CSSObject => ({
    width: 220,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  const closedMixin = (theme: Theme): CSSObject => ({
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(6)} + 1px)`,
    },
  });



  const courseState = useSelector((state: RootState) => state.activeCourse);

  return (
    <Drawer variant="permanent"  sx={{
      ...(open && { ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme), }),
      ...(!open && { ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme), }),
    }}>
      <DrawerHeader sx={{ marginTop: alertState.alertOpen?12:8 }}>
        {open ? <IconButton onClick={handleDrawerClose}><ChevronLeftIcon /></IconButton>
          : <IconButton onClick={handleDrawerOpen}><ChevronRightIcon /></IconButton>
        }
      </DrawerHeader>
      {courseState.course.certificate && courseState.course.certificate.code
        ? <Box sx={{ ml: 1 }}>
          <Badge>
            <CertificateIcon url={courseState.course.certificate.code} size={30} />
            {courseState.course.isPerfect ?
              <Tooltip title='Puntaje Perfecto'>
                <Box height={14} width={14}
                  position={'absolute'} right={-3} bottom={-3}
                  component="img"
                  alt="Puntaje Perfecto"
                  src={starImage} />
              </Tooltip>
              : null}
          </Badge>


        </Box>
        : <></>
      }
      <ListStepper openDelay={openDelay}></ListStepper>
    </Drawer>
  );
}