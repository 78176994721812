export enum ContentType {
  PythonLesson = 'PythonLesson',
  Questions = 'Questions',
  JupyterLesson = 'JupyterLesson',
  Reading = 'Reading',
}

export enum ProgramStatus {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  Completed = 'Completed'
}

export enum CourseStatus {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export enum ContentStatus {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  Failed = 'Failed',
  Completed = 'Completed',
  Perfect = 'Perfect',
  Pending = "Pending",
}