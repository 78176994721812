import { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { ContentStatus } from '../enums/programs.enums';

export function CourseProgress(props: any) {
    const [progression, setProgression] = useState<number>(0)
    useEffect(() => {
        let contentCounter = 0
        let progress = 0
        //console.log('getcourse_learn:',res.data)
        props.data.chapters.map((chapter: any) => {
            chapter.contents.map((content: any) => {
                contentCounter++;
                if (content.status === ContentStatus.Completed || content.status === ContentStatus.Perfect) {
                    progress++;
                }
                return true;
            })
            return true;
        })
        setProgression(Math.round((100 / contentCounter) * progress));
    }, [props.data])

    return (
        <Grid container display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'end'}
            sx={{ width: 100 }}>
            <Grid item sx={{ minWidth: 100 }}>
                <LinearProgress sx={{ height: 10, borderRadius: 5 }} variant="determinate" color='primary' value={progression} />
            </Grid>
            <Grid item sx={{ minWidth: 35 }}>
                <Typography variant="body2" align='center' color="text.secondary" sx={{ height: 15 }}>{`${progression}%`}</Typography>
            </Grid>
        </Grid>
    )
}