import React, {  useState } from 'react';
import { FormControl, FormControlLabel, Paper, Radio, RadioGroup, Box, Button, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as DOMPurify from 'dompurify';
import './styles/imgQuestionlesson.css'
type Props = { item: any, answer: any, setNumberAnswered: Function, setPage: Function, isLastPage: boolean, page: number, sendAvailable: boolean, send: Function,expired:boolean }

const QuestionElement: React.FC<Props> = ({ item, answer, setNumberAnswered, setPage, isLastPage, page, sendAvailable, send, expired}) => {
  const [clickedOnce, setClickedOnce] = useState<boolean>(false)
  const cleanData = (html: string) => {
    return DOMPurify.sanitize(html)
  }

  const handleToggle = (e: any) => {
    answer[e.target.name] = e.target.checked;
    if (answer.alternative1 === false && answer.alternative2 === false && answer.alternative3 === false && answer.alternative4 === false && answer.alternative5 === false) {
      setNumberAnswered((value: number) => value - 1)
      setClickedOnce(false)
    } else {
      if (!clickedOnce) {
        setNumberAnswered((value: number) => value + 1)
        setClickedOnce(true)
      }

    }
  }

  const handleToggleRB = (e: any) => {
    answer.alternative1 = false;
    answer.alternative2 = false;
    answer.alternative3 = false;
    answer.alternative4 = false;
    answer.alternative5 = false;
    answer[e.target.name] = true;
    if (!clickedOnce) {
      setNumberAnswered((value: number) => value + 1)
      setClickedOnce(true)
    }
  }

  const updatePage=(signo:boolean)=>{
    let newPage=page
    if(signo){
      newPage+=1
    }else{
      newPage-=1
    }
    setPage((page: number) => newPage)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={7}>
        <Box sx={{
          '&::-webkit-scrollbar': {
            width: 0
          }, maxHeight: '66vh', overflow: 'auto'
        }}>
          <Typography  onCopy={(e)=>{e.preventDefault()}} sx={{userSelect:"none",WebkitUserSelect:"none",WebkitTouchCallout:"none",msUserSelect:"none",MozUserSelect:"none"}} variant='inherit' dangerouslySetInnerHTML={{ __html: cleanData(item.detail) }}></Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper elevation={1} sx={{ p: 1 }}>
          <FormControl sx={{ width: "100%" }}>
            <Typography onCopy={(e)=>{e.preventDefault()}} sx={{userSelect:"none",WebkitUserSelect:"none",WebkitTouchCallout:"none",msUserSelect:"none",MozUserSelect:"none"}}>{item.question}</Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel value="1" control={item.multiselection ? <Checkbox name='alternative1' onChange={handleToggle} /> : <Radio name='alternative1' onChange={handleToggleRB} />} label={item.alternative1} />
              <FormControlLabel value="2" control={item.multiselection ? <Checkbox name='alternative2' onChange={handleToggle} /> : <Radio name='alternative2' onChange={handleToggleRB} />} label={item.alternative2} />
              <FormControlLabel value="3" control={item.multiselection ? <Checkbox name='alternative3' onChange={handleToggle} /> : <Radio name='alternative3' onChange={handleToggleRB} />} label={item.alternative3} />
              <FormControlLabel value="4" control={item.multiselection ? <Checkbox name='alternative4' onChange={handleToggle} /> : <Radio name='alternative4' onChange={handleToggleRB} />} label={item.alternative4} />
              <FormControlLabel value="5" control={item.multiselection ? <Checkbox name='alternative5' onChange={handleToggle} /> : <Radio name='alternative5' onChange={handleToggleRB} />} label={item.alternative5} />
            </RadioGroup>

          </FormControl>

        </Paper>
        <Grid sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", marginTop: 1 }}>
          {
          expired?<Button disabled variant='contained'>Expirado</Button>:
          isLastPage && page === 1 ? <Tooltip title={sendAvailable ? 'Falan preguntas por responder' : ""}><span><Button  variant='contained' disabled={sendAvailable||expired} onClick={() => { send() }}>{expired?"Expirado":"Enviar"}</Button></span></Tooltip> 
          :
            (isLastPage ? <><Button variant='outlined' disabled={expired} sx={{ marginRight: 1 }} onClick={() => { updatePage(false)}}>Anterior</Button><Tooltip title={sendAvailable ? 'Falan preguntas por responder' : ""}><span><Button variant='contained' disabled={sendAvailable} onClick={() => { send() }}>Enviar</Button></span></Tooltip></>
              :
              (page === 1 ? <Button variant='contained' onClick={() => { updatePage(true)}}>Siguiente</Button>
                :
                <><Button variant='outlined' sx={{ marginLeft: 1 }} onClick={() => { updatePage(false)}}>Anterior</Button><Button variant='contained' sx={{ marginLeft: 1 }} onClick={() => { updatePage(true)}}>Siguiente</Button></>))
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuestionElement;