import React, { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import MetadataApi from '../apis/metadata.api';
import { myContext } from '../contexts/Context'
import { AppBar, Avatar, Badge, Box, Button, Container, IconButton, Menu, MenuItem, Skeleton, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
//import { grey } from '@mui/material/colors';
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { WebSocketContext } from '../contexts/WebsocketContext';
import { RootState } from '../stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification, getAllNotification } from '../stores/slices/notificationSlice';
import { Role } from '../enums/roles.enums';
import { removedSession } from '../common/SessionRewindMethods';
import { closeFilm } from '../stores/slices/sessionRewindSlice';
export default function NavBar() {
  const ctx = useContext(myContext);
  const socket = useContext(WebSocketContext)
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const notificationState = useSelector((state: RootState) => state.notifications);
  const limitedNotification = notificationState.notifications.slice(0, 3)
  const location = useLocation();
  const logout = () => {
    const token = localStorage.getItem('token');
    MetadataApi.get("/auth/logout", {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    }).then((res: AxiosResponse) => {
      if (res.data === "success") {

        localStorage.removeItem('token');
        window.location.href = "/"
      }
    }, () => {
      window.location.href = "/"
    })
  }

  const sessionRewind = useSelector((state: RootState) => state.sessionRewind);
  
  const changePage=(route:string)=>{
    const isLearnPage = matchPath('/learn/:id', location.pathname);
    if(sessionRewind.isFilming.length>0&&isLearnPage){
     
      window.location.href = "/"+route
      removedSession()
      dispatch(closeFilm())
    }
    
    else{
      if (location.pathname === "/" + route) {
        window.location.reload();}else{
          navigate("/"+route)
    
        }
      }
    
  }

  useEffect(() => {

    if (ctx.user && socket) {
      socket.emit("connected", { username: ctx.user.username, role: Role.User })
      socket.on('navBarNotification', (data) => {
        if ((!notificationState.notifications.some(obj => obj.contentId === data.contentId) && notificationState.chatOpen === false)) {
          dispatch(addNotification(data))
        }
      })
      return () => {
        socket.off('connect')
        socket.off('navBarNotification')
      }
    }


  }, [socket, ctx.user, dispatch, notificationState])

  useEffect(() => {
    if (ctx.user) {

      const token = localStorage.getItem('token');
      MetadataApi.get("/learncomments/getallnotifications/" + ctx.user.username, {
        headers: ({
          Authorization: 'Bearer ' + token
        })
      }).then((res: AxiosResponse) => {
        dispatch(getAllNotification(res.data))
      })
    }
  }, [ctx.user, dispatch])



  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const [anchorElNotification, setAnchorElNotification] = React.useState<null | HTMLElement>(null);
  const handleOpenNotificationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseUserNotification = () => {
    setAnchorElNotification(null);
  };
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    if (ctx.loading) return;
    setIsLoading(true)

  }, [ctx])
const moveToNotifications=()=>{
  handleCloseUserNotification()
  changePage("notifications")
}
  return (

    <AppBar position="relative" sx={{ zIndex: theme.zIndex.drawer + 1, bgcolor: 'white' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          {isLoading ?
            <>
              <Typography
                variant="h5"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.1rem',
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                }}
              >
                Deta&lt;code&#47;&gt;
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="primary"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {ctx && ctx.user && Object.keys(ctx.user).length !== 0 ? (
                    <MenuItem key="My Home" onClick={()=>{changePage("myhome")}}>
                      <Typography textAlign="center">My aprendizaje</Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem key="Home" onClick={()=>{changePage("")}}>
                      <Typography textAlign="center">Inicio</Typography>
                    </MenuItem>
                  )}
                  <MenuItem key="Program" onClick={()=>{changePage("program")}}>
                    <Typography textAlign="center">Programas</Typography>
                  </MenuItem>
                  <MenuItem key="Course" onClick={()=>{changePage("course")}}>
                    <Typography textAlign="center">Cursos</Typography>
                  </MenuItem>
                </Menu>
              </Box>

              <Typography
                variant="h5"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.1rem',
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                }}
              >
                Deta&lt;code&#47;&gt;
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {ctx && ctx.user && Object.keys(ctx.user).length !== 0 ? (
                  <Button
                    key='My Home'
                    onClick={()=>{changePage("myhome")}}
                    sx={{ my: 2, display: 'block' }}
                  >Mi aprendizaje
                  </Button>
                ) : (
                  <Button
                    key='Home'
                    onClick={()=>{changePage("")}}
                    sx={{ my: 2, display: 'block' }}
                  >Inicio
                  </Button>

                )}
                <Button
                  key='Program'
                  onClick={()=>{changePage("program")}}
                  sx={{ my: 2, display: 'block' }}
                >Programas
                </Button>
                <Button
                  key='Course'
                  onClick={()=>{changePage("course")}}
                  sx={{ my: 2, display: 'block' }}
                >Cursos
                </Button>
              </Box>

              {ctx && ctx.user && Object.keys(ctx.user).length !== 0 ? (
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Notificaciones" sx={{ marginRight: 10 }}>
                    <IconButton sx={{ p: 1.5 }} onClick={handleOpenNotificationMenu} >
                      <Badge badgeContent={notificationState.notifications.length} color='error'>
                        <NotificationsIcon></NotificationsIcon>
                      </Badge>
                    </IconButton >
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElNotification}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}

                    open={Boolean(anchorElNotification)}
                    onClose={handleCloseUserNotification}
                  >
                    
                    {limitedNotification.length===0?
                    <MenuItem  >
                      <span style={{ display: "inline" }}>Sin notificaciones </span>
                    </MenuItem>
                    :
                   
                    limitedNotification.map((notification: any, index: number) => {
                      return (
                       
                         <MenuItem divider key={index}  sx={{ justifyContent: "left" }} onClick={()=>{changePage("learn/" + notification.courseId + "/" + notification.contentId)}} >
                          <Typography>
                          <span style={{ fontWeight: "bold", display: "inline" }}>Nuevo mensaje en: </span>
                              {" Curso "}
                              <span style={{color:theme.palette.primary.main}}>
                              {notification.course_name}
                              </span>
                              {". Contenido "}
                              <span style={{color:theme.palette.primary.main}}>
                              { notification.content_name}
                                </span>
                          </Typography>
                        
                      
                      </MenuItem>
                      )
                    })}
                      <MenuItem sx={{alignItems:"center",justifyContent:"center",display:"flex"}} onClick={()=>{moveToNotifications()}}  >
                          
                          <span style={{ display: "inline",alignContent:"center",justifyContent:"center",fontWeight: "bold", }}>Ver Todas las notificaciones</span>
                            
                      </MenuItem>
                      
                  
                  </Menu>
                  <Tooltip title="Open settings" sx={{ marginLeft: 10 }}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                      <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{ctx.user.firstname !== undefined ? ctx.user.firstname.charAt(0).toUpperCase() : "U"}</Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <Typography sx={{ pr: 2, pl: 2 }} textAlign='center'>Usuario: {ctx.user.firstname} {ctx.user.lastname}</Typography>
                    <MenuItem key="Profile" onClick={()=>{changePage("profile")}} sx={{ justifyContent: "center" }}>
                      <Typography>Perfil</Typography>
                    </MenuItem>
                    <MenuItem key="Help" component={Link} to='/help' target="_blank" sx={{ justifyContent: "center" }}>
                      <Typography>Ayuda</Typography>
                    </MenuItem>
                    <MenuItem key="Logout" onClick={logout} component={Link} to="/logout" sx={{ justifyContent: "center" }}>
                      <Typography>Cerrar sesión</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                  <Button
                    key='Login'
                    component={Link}
                    to='/login'
                    sx={{ my: 2, textAlign: 'center' }}
                  >Iniciar sesión
                  </Button>
                  {/* <Button
                    key='Register'
                    component={Link}
                    to='/register'
                    sx={{ my: 2 }}
                  >Registrarse
                  </Button> */}
                </Box>
              )}
            </>

            :
            <>
              <Typography
                variant="h5"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.1rem',
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                }}
              >
              <Skeleton/>
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="primary"
                >
                  <Skeleton />
                </IconButton>

              </Box>

              <Typography
                variant="h5"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.1rem',
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                }}
              >
               <Skeleton/>
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                <Button
                  key='Home'
                  component={Link}
                  to='/'
                  sx={{ my: 2, display: 'block' }}
                ><Skeleton/>
                </Button>
                <Button
                  key='Program'
                  component={Link}
                  to='/program'
                  sx={{ my: 2, display: 'block' }}
                ><Skeleton/>
                </Button>
                <Button
                  key='Course'
                  component={Link}
                  to='/course'
                  sx={{ my: 2, display: 'block' }}
                ><Skeleton/>
                </Button>
              </Box>

              <Box sx={{ flexGrow: 0, display: 'flex' }}>
                <Button
                  key='Login'
                  component={Link}
                  to='/login'
                  sx={{ my: 2, textAlign: 'center' }}
                ><Skeleton/>
                </Button>
                {/* <Button
                    key='Register'
                    component={Link}
                    to='/register'
                    sx={{ my: 2 }}
                  >Registrarse
                  </Button> */}
              </Box>

            </>
          }
        </Toolbar>
      </Container>
    </AppBar>

  )
}