
//importar variable de apikey
const sessionRewindKey = process.env.REACT_APP_SESSIONREWIND_API_KEY
const createAndAppendCustomScript=(username:string,courseId:number)=>{
  const apiUrl = process.env.REACT_APP_MAIN_API;
  const token = localStorage.getItem('token');

  const script = document.createElement('script');
  const apiVersion = 'v1';
  const url1=`${apiUrl}/api/${apiVersion}/`
      script.text = `
        !function (o) {
          var w = window;
          w.SessionRewindConfig = o;
          var f = document.createElement("script");
          f.async = 1, f.crossOrigin = "anonymous", f.src = "https://rec.sessionrewind.com/srloader.js";
          var g = document.getElementsByTagName("head")[0];
          g.insertBefore(f, g.firstChild);
        }({
          apiKey: '`+ sessionRewindKey + `',
          createNewSession: true,
          startRecording: true,
          userInfo: {
            userId: '`+ username + '_' + courseId + `',
          },
          sessionInfo: {
            Course: '`+ courseId + `',
          },
          onLoad: () => {  
           setTimeout(() => {
    window.sessionRewind.getSessionUrl(async (url) => {
        console.log(url);

        axios.post('${url1}sessionrewind/upload', {
            courseId: '${courseId}',
            username: '${username}',
            url: url
        }, {
            headers: {
                Authorization: 'Bearer ${token}'
            }
        });
    });
}, 3000);
           
          }  
      });
      `;
      
    document.body.appendChild(script);
}
const initSession=(username:string,courseId:number)=>{
  
  var axiosScript = document.createElement('script');
  axiosScript.src = 'https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js';
  axiosScript.onload = () => createAndAppendCustomScript(username,courseId);
  document.head.appendChild(axiosScript);
  
}
const removedSession=()=>{
  const script = document.createElement('script');
  script.id = 'sessionRewindScript';
  script.text = `
window.sessionRewind = undefined;
window.SessionRewindConfig = undefined;
window.SRLoader = undefined;

if (window.sessionRewind && typeof window.sessionRewind.stopSession === 'function') {
  window.sessionRewind.stopSession();
}
`;
  //script.text = `window.sessionRewind.stopSession()`;
  document.body.appendChild(script);
  localStorage.removeItem('SessionRewindSessionData')
  sessionStorage.removeItem('SessionRewindTabId')
}

export {initSession,removedSession}