
import { Button, Skeleton,Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MetadataApi from '../apis/metadata.api';
import { myContext } from '../contexts/Context';
import { ContentStatus } from '../enums/programs.enums';
import { setContentStatus, setCourseCertificate, setCourseStatus } from '../stores/slices/activeCourseSlice';
import { RootState } from '../stores/store';
import MessageDialog from './MessageDialog';
import * as DOMPurify from 'dompurify';
import './styles/imgReadinglesson.css'
import MessageResultDialog from './MessageResultDialog';
import { ChatBox } from './ChatBox';
import MessageDialogCertificate from './MessageDialogCertificate';
import { CourseCountdown } from './CourseCountdown';
type Props = { contentId: number,expired:boolean,setCourseTimeRemaining:Function, }

const ReadingLesson: React.FC<Props> = ({ contentId,expired,setCourseTimeRemaining }) => {

  const ctx = useContext(myContext);
  const courseState = useSelector((state: RootState) => state.activeCourse);
  const dispatch = useDispatch();
  const [resultTrigger, setResultTrigger] = useState(0);
  const [errorTrigger, setErrorTrigger] = useState(0);
  const [certTrigger, setCertTrigger] = useState(0);
  const [numberNotifications, setNumberNotifications] = useState<number>(0)
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [perfectCertTrigger, setPerfectCertTrigger] = useState(0);
  const cleanData = (html: string) => {
    return DOMPurify.sanitize(html)
  }
  const getnumbernotifications = useCallback(() => {

    const token = localStorage.getItem('token');
    MetadataApi.post("learncomments/getnumbernotifications", {
      courseId: courseState.course.courseId,
      codelessonId:-1,
      contentId: contentId,
      username: ctx?.user?.username,
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
     
      setNumberNotifications(res.data)
    })
  }, [contentId, courseState.course.courseId, ctx.user])

  useEffect(() => {
    getnumbernotifications()
  }, [getnumbernotifications])
  useEffect(() => {
    if (!ctx || !ctx.user || ctx.loading) return;
    setLoading(true);
    const token = localStorage.getItem('token');
    MetadataApi.post(`learncontents/getcontent`, {
      contentId: contentId,
      username: ctx.user.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      // console.log('getcontent_learn:',res.data)
      setData(res.data)
      setLoading(false)
    }, () => {
      // window.location.href = "/"
    })
  }, [ctx, contentId])

  const handleClick = (type: string) => () => {
    //console.log(type);
    if (!ctx || !ctx.user) return;
    const token = localStorage.getItem('token');
    MetadataApi.post(`learncontents/validatereading`, {
      contentId: contentId,
      username: ctx.user.username,
      courseId: courseState.course.courseId
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      if(res.data){
   //console.log('savecontent coursestatus',res.data);
        dispatch(setContentStatus(type));
        dispatch(setCourseStatus(res.data.course.courseStatus));
        if (res.data.course.courseCertificate && res.data.course.courseCertificate !== '') {
          dispatch(setCourseCertificate(res.data.course.courseCertificate));
          if (res.data.contentData.course.isPerfect) { //caso donde llega perfect
            setPerfectCertTrigger((perfectCertTrigger) => perfectCertTrigger + 1);
          } else {
            setCertTrigger((certTrigger) => certTrigger + 1);
          }
        } else {
          setResultTrigger(resultTrigger + 1)
        }
      }
   
    }, () => {
      setErrorTrigger((errorTrigger) => errorTrigger + 1);
    })
  };

  if (!contentId || !ctx) return <>Error</>

  return (
    <>
      <MessageResultDialog trigger={resultTrigger} contentState={courseState.content.status}  />
      <MessageDialog trigger={errorTrigger} message={'Error al guardar en Base de Datos'} />
      <MessageDialogCertificate trigger={certTrigger} message={'Felicidades, has finalizado el curso y obtenido tu certificado, puede intentar terminar el curso con estado Perfecto completando todos los ejecicios perfectamente.'} isPerfect={false}/>
      <MessageDialogCertificate trigger={perfectCertTrigger} message={'Felicidades, has finalizado el curso de manera perfecta y obtenido tu certificado '} isPerfect={true}/>
      {!loading ?
        <>
        {courseState.course.hours_limit?<Box
                sx={{
                  mt: 2,
                  position: "absolute",
                  right: 30,
                  display: { xs: "none", md: "flex" },
                }}
              >
                <CourseCountdown course_hours={courseState.course.hours} start_date={courseState.course.start_date} isLearn={true} setCourseTimeRemaining={setCourseTimeRemaining} />
              </Box>:
          <ChatBox contentId={data.contentId} codelessonId={-1} setNumberNotifications={setNumberNotifications} numberNotifications={numberNotifications} getNumberNotifications={getnumbernotifications} page={1} multiplesChats={false}  ></ChatBox>}
          <Grid container spacing={2} p={3}>

            <Grid item xs={12} md={5}>
              <Typography variant='h5'>{courseState.content.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} >

              <Box display="flex" sx={{ justifyContent: { xs: 'center', md: 'center' } }}>
                <Button variant='contained' disabled={expired} onClick={handleClick(ContentStatus.Perfect)}
                >{expired?"Expirado":courseState.content.status === ContentStatus.Perfect ? "Siguiente" : "Completar"}</Button>
              </Box>


            </Grid>
            <Grid item xs={12} md={5}>
              
            <Grid item xs={12} sm={12}>
              {courseState.course.hours_limit ? (
                  <Box sx={{ display: { xs: "flex", md: "none" } }}>
                    <CourseCountdown course_hours={courseState.course.hours} start_date={courseState.course.start_date} isLearn={true} setCourseTimeRemaining={setCourseTimeRemaining} />
                  </Box>
                
              ) : null}
            </Grid>
            </Grid>
            <Typography variant='inherit' dangerouslySetInnerHTML={{ __html: cleanData(data.reading.text) }} />

            <Grid item xs={12} md={12}>
              <Box sx={{ textAlign: 'center' }}>

                <Button variant='contained' disabled={expired}  onClick={handleClick(ContentStatus.Perfect)}
                >{expired?"Expirado":courseState.content.status === ContentStatus.Perfect ? "Siguiente" : "Completar"}</Button>


              </Box>
            </Grid>
            <Grid item xs={12}><br /></Grid>
          </Grid>
        </>
        :
        <Skeleton></Skeleton>

      }
    </>
  )
}

export default ReadingLesson;