import { Card, CardContent, Typography, IconButton, List, ListItem, Link } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MetadataApi from '../../apis/metadata.api'
import Container from '@mui/material/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { HelpSkeleton } from './helpSkeleton';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
type helpQuestions = {
    helpId: string;
    type: string;
    question: string;
    answer: string;
}
export default function HelpQuestions() {

    const navigate = useNavigate();
    const goBack = () => {
        let path = `/help`
        navigate(path);
    }
    const { typequestion } = useParams();
    const [helpQuestions, setHelpQuestions] = useState([])
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        const typeQuestion=typequestion
        //console.log(typeQuestion)
        const token = localStorage.getItem('token');
        MetadataApi.get("help/getquestions/" + typeQuestion, {
            headers: ({
                Authorization: 'Bearer ' + token
            })
        }).then((res: AxiosResponse) => {
            //console.log(res.data)
            setHelpQuestions(res.data)
            setLoading(false)
        })
    }, [typequestion])



    return (
        <>
            <Container className='centered'>
                <IconButton onClick={goBack}>
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant='h4' alignItems='center' sx={{ paddingBottom: 5 }} align="center">
                    {typequestion==="Account" ? "Preguntas de la cuenta":null}
                    {typequestion==="Certificate" ? "Preguntas sobre certificados":null}
                    {typequestion==="Frecuent" ? "Preguntas frecuentes":null}
                    {typequestion==="Functionality" ? "Preguntas de uso":null}
                    {typequestion==="JupyterLesson" ? "Preguntas de JupyterLesson":null}
                    {typequestion==="Question" ? "Preguntas de QuestionLesson":null}
                    </Typography>
                <Typography>Preguntas</Typography>
                {loading ? <HelpSkeleton /> :
                    <>
                        <List sx={{ listStyleType: 'disc', pl: 4, paddingBottom: 5 }}>
                            {helpQuestions.map((question: helpQuestions) => {
                                return (
                                    <Container>
                                        <ListItem sx={{ display: 'list-item' }} disablePadding>
                                            <Link href={"#" + question.helpId}>
                                                {question.question}
                                            </Link>
                                        </ListItem>
                                    </Container>
                                )
                            })}
                        </List>
                        {helpQuestions.map((question: helpQuestions) => {
                            return (
                                <Container sx={{ paddingBottom: 5 }}>
                                    <section id={question.helpId} key={question.helpId}>
                                        <Card sx={{ height: '100%', width: '100%' }}>
                                            <CardContent>
                                                <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }} >
                                                    {question.question}
                                                </Typography>
                                                <Typography variant="body2" >
                                                    {question.answer}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </section>
                                </Container>
                            )
                        })}
                    </>
                }
            </Container>
        </>
    )
}