import { useContext, useEffect, useState } from 'react';
import { Alert, Avatar, Button, Grid, Icon, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { Box, Container } from '@mui/system';
import { myContext } from '../contexts/Context';
import Card from '@mui/material/Card';
import axios, { AxiosResponse } from 'axios';
import MetadataApi from '../apis/metadata.api';
import validator from 'validator';
import { ChangePasswordDialog } from '../components/ChangePasswordDialog';

export default function Profile() {

  const ctx = useContext(myContext);
  const theme = useTheme();

  const [done, SetDone] = useState<string>("")
  const [passwordDone, SetPasswordDone] = useState<string>("")
  const [countries, setCountries] = useState<any>([])
  const [user, setUser] = useState<any>({
    firstname: ctx.user?.firstname,
    lastname: ctx.user?.lastname,
    username: ctx.user?.username,
    age: 0,
    email: ctx.user?.email,
    country: "",
    linkedin: "",
    degree: "",
  })

  const [firstnameError, setFirstnameError] = useState('')
  const [lastnameError, setLastnameError] = useState('')
  const [linkedinError, setLinkedinError] = useState('')
  const [ageError, setAgeError] = useState('')
  const [degreeError, setDegreeError] = useState('')

  useEffect(() => {
    let countriesNames: any = []
    axios.get("https://restcountries.com/v3.1/all").then((res: AxiosResponse) => {
      res.data.map((country: any) => {
        countriesNames.push(country.name.common)
        return true
      })
      setCountries(countriesNames.sort())
    })
    const token = localStorage.getItem('token');
    MetadataApi.get(`user/getuser/` + ctx.user?.username, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      let user = {
        firstname: res.data.firstname,
        lastname: res.data.lastname,
        username: ctx.user?.username,
        age: res.data.age,
        email: ctx.user?.email,
        country: res.data.country,
        linkedin: res.data.linkedin,
        degree: res.data.degree,
      }
      setUser(user)
    },)
  }, [ctx.user?.email, ctx.user?.username])

  const updateUser = (e: any) => {
    e.preventDefault()
    if (ageError !== '' || degreeError !== '' || linkedinError !== '' || firstnameError !== '' || lastnameError !== '')
      return;
    if (!ctx.user || ctx.user.username !== user.username)
      return;

    const token = localStorage.getItem('token');
    MetadataApi.put(`user/updateUser`, {
      user
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      ctx.user?.updateUser((prev: any) => ({ ...prev, firstname: user.firstname }))
      ctx.user?.updateUser((prev: any) => ({ ...prev, lastname: user.lastname }))
      SetDone('Realizado');
    },)

  }

  const validateAge = (age: string) => {
    SetDone('');
    let min = 0
    let max = 200
    if (validator.isNumeric(age)) {
      if (parseInt(age) < min) {
        age = min.toString()
      }
      if (parseInt(age) > max) {
        age = max.toString()
      }
      setUser({ ...user, age: age })
      setAgeError('')
    } else {
      setUser({ ...user, age: "" })
      setAgeError('Ingresar una edad entre 1 y 200!')
    }
  }

  const validateLinkedin = (linkedin: string) => {
    SetDone('');
    setUser({ ...user, linkedin: linkedin })
    if (validator.isURL(linkedin)) {

      setLinkedinError('')
    } else {

      setLinkedinError('Ingresar una url válida!')
    }
  }

  const validateDegree = (degree: string) => {
    SetDone('');
    let newDegree = degree.split(" ").join("")
    setUser({ ...user, degree: degree })
    if (validator.isAlpha(newDegree, 'es-ES')) {

      setDegreeError('')
    } else {

      setDegreeError('Ingresar un nombre válido!')
    }
  }
  const validateFirstname = (firstname: string) => {
    SetDone('');
    setUser({ ...user, firstname: firstname })
    if (validator.isAlpha(firstname, 'es-ES')) {
      setFirstnameError('')
    } else {
      setFirstnameError('Ingresar un nombre válido!')
    }
  }
  const validateLastname = (lastname: string) => {
    SetDone('');
    setUser({ ...user, lastname: lastname })
    if (validator.isAlpha(lastname, 'es-ES')) {
      setLastnameError('')
    } else {
      setLastnameError('Ingresar un apellido válido!')
    }
  }

  const handleInputChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }
  return (
    <div className="centered">
      {ctx && ctx.user && Object.keys(ctx.user).length !== 0 ?
        <>
          <Container className="centered">
            <Grid container justifyContent="center" pb={5}>
              <Grid item xs={10} justifyContent="center">
                <Box display="flex" alignItems={"center"} justifyContent="center">
                  <Icon sx={{ pb: 1, width: 50, height: 50 }}>
                    <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 50, height: 50 }}>{ctx.user.firstname !== undefined ? ctx.user.firstname.charAt(0).toUpperCase() : "U"}</Avatar>
                  </Icon>
                </Box>
                <Typography sx={{ marginBottom: 2 }} component="h1" variant="h5" alignItems='center' align='center'>{ctx.user.username}</Typography>
                <Card>
                  <form onSubmit={updateUser} id="form">
                    <Box margin={2}>

                      <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                        <Grid item xs={4}>
                          <Typography variant='body1' alignItems='left' align='left' >Nombre:
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            id="firstname"
                            name="firstname"
                            onChange={e => { validateFirstname(e.target.value) }}
                            error={firstnameError !== ''}
                            helperText={firstnameError}
                            type="text"
                            value={user.firstname}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            id="lastname"
                            name="lastname"
                            onChange={e => { validateLastname(e.target.value) }}
                            error={lastnameError !== ''}
                            helperText={lastnameError}
                            type="text"
                            value={user.lastname}
                            variant="standard"
                          />
                        </Grid>
                      </Grid>

                      <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                        <Grid item xs={4}>
                          <Typography variant='body1' alignItems='left' align='left' >Correo:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant='body1' alignItems='left' align='left' >{ctx.user.email}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                        <Grid item xs={4}>
                          <Typography variant='body1' alignItems='left' align='left' >Contraseña:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <ChangePasswordDialog setPasswordDone={SetPasswordDone} />
                        </Grid>
                      </Grid>

                      <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                        <Grid item xs={4}>
                          <Typography variant='body1' alignItems='left' align='left' >Edad:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            required
                            id="age"
                            name="age"
                            onChange={e => { validateAge(e.target.value) }}
                            error={ageError !== ''}
                            helperText={ageError}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 250 }, }}
                            value={user.age}
                            variant="standard"
                          />
                        </Grid>
                      </Grid>

                      <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                        <Grid item xs={4}>
                          <Typography variant='body1' alignItems='left' align='left' >Título:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="degree"
                            name="degree"
                            onChange={e => { validateDegree(e.target.value) }}
                            error={degreeError !== ''}
                            helperText={degreeError}
                            label=""
                            multiline
                            type="text"
                            value={user.degree}
                            fullWidth
                            variant="standard"
                          />
                        </Grid>
                      </Grid>

                      <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                        <Grid item xs={4}>
                          <Typography variant='body1'>País:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Select id="country"
                            name="country"
                            onChange={handleInputChange}
                            value={user.country}
                            size='small'
                            sx={{ width: "100%" }}
                          >{countries.map((country: any) => (
                            <MenuItem key={country} value={country}>
                              <Typography variant='body1'>{country}</Typography>
                            </MenuItem>
                          ))}</Select>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ marginBottom: 2, alignItems: 'center' }}>
                        <Grid item xs={4}>
                          <Typography variant="body1" >Linkedin:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="linkedin"
                            name="linkedin"
                            onChange={e => { validateLinkedin(e.target.value) }}
                            error={linkedinError !== ''}
                            helperText={linkedinError}
                            label=""
                            multiline
                            type="text"
                            value={user.linkedin}
                            fullWidth
                            variant="standard"
                          />
                        </Grid>
                      </Grid>

                      <Button variant='contained' type='submit' sx={{ rowGap: 2 }}>Guardar</Button>
                      {done !== "" ? (
                        <Box mt={2}>
                          <Alert variant="filled" severity="success" sx={{ width: 200, top: 5 }}>
                            Usuario actualizado.
                          </Alert>
                        </Box>
                      ) : (<></>)}
                      {passwordDone === "success" ? (
                        <Box mt={2}>
                          <Alert variant="filled" severity="success" sx={{ width: 200, top: 5 }}>
                            Contraseña actualizada.
                          </Alert>
                        </Box>
                      ) :<></>
                    }

                    </Box>
                  </form>
                </Card>
              </Grid>

            </Grid>

          </Container>
        </>
        : ''}
    </div>
  )
}