
import { Fab, Tooltip, IconButton} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Fragment} from 'react';
import { useTheme, Theme, CSSObject } from '@mui/material/styles';
type Props = {
    ShowButtonResponses: boolean,
    handleOpenWindow: any,
    isWindowReponsesOpen: boolean,
    handleCloseWindow: any,
    tests: any,
    openDrawer: boolean,


}

export function TestWindow({ ShowButtonResponses, handleOpenWindow, isWindowReponsesOpen, handleCloseWindow, tests, openDrawer }: Props) {
 
    const theme = useTheme();
   
    const openedMixin = (theme: Theme): CSSObject => ({
        width:"30%" ,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    }
    );
    const closedMixin = (theme: Theme): CSSObject => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: "35%",
        [theme.breakpoints.up('sm')]: {
            width: `35%`,
        },
    });
    
    
    return (
        <>
            {ShowButtonResponses ?
                <Fab onClick={handleOpenWindow} variant='extended'
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        lef: 160,
                        backgroundColor: "#e0e0e0",
                        borderRadius: 0,
                        width: "100px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        ...(openDrawer && { ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme), }),
                        ...(!openDrawer && { ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme), }),
                    }}>
                    <Typography variant='body1' textTransform="none" >Resultados de Test </Typography><ExpandLess fontSize='small' />
                </Fab> : null}
            {isWindowReponsesOpen ?
                <Grid container spacing={2} >
                    <Grid item sm={12} md={12}>
                    <Box
                        sx={{
                            border:"solid",
                            borderWidth:0.5,
                            overflow: "hidden",
                            position: 'fixed',
                            bottom: 0   ,
                            width: '35%',
                            height: "200px",
                            ...(openDrawer && { ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme), }),
                            ...(!openDrawer && { ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme), }),
                        }}
                    >
                       
                            <Box

                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    overflow:"hidden",
                                    backgroundColor: "#e0e0e0"
                                }}
                            >   
                           
                                <Typography sx={{marginLeft:1}}
                                 variant='body1'>Resultados de Tests</Typography>
                                <IconButton onClick={handleCloseWindow}>
                                    <ExpandMore />
                                </IconButton>
                            </Box>
                            <Box sx={{ height: "100%", maxHeight: "170px", overflow: "auto",backgroundColor: "#ffffff"}}>

                                <Grid container>
                              
                                { tests.map((test: any, index: number) => (
                                        <Fragment key={index}>
                                           
                                            <Grid item xs={openDrawer?12:test.expected_output?12:3}>
                                            <Typography variant='body1' marginBottom={1}>
                                                Test {index+1}: {/* <strong>{test.output}</strong> */} {test.result===1?
                                                <>
                                                <Tooltip title={"Correcto"}><CheckCircleIcon color='success' fontSize='medium' sx={{ verticalAlign: "-7px" }} /></Tooltip>
                                                {test.expected_output? <><strong>{"Input: "}</strong>{test.input}<strong>{ "\n Output: "}</strong>{test.output}</>:""}
                                                </>
                                               
                                                :
                                                test.result===0?
                                
                                                <> <Tooltip title={
                                                    "Incorrecto. "+ 
                                                    
                                                    (test.expected_output ? " Output esperado: " + test.expected_output : "" ) +"."+
                                                    (test.error?"Error: "+test.error:"")
                                                  }><CancelIcon color='error' fontSize='medium' sx={{ verticalAlign: "-7px" }} /></Tooltip>
                                                 
                                                 {
                                                   test.expected_output? <><strong>{"Input: "}</strong>{test.input}
                                                   </>:""}

                                                   {
                                                    test.expected_output?
                                                    test.error? 
                                                    <><strong>{ "\n Output: "}</strong>{test.error}</>
                                                    
                                                    :
                                                    <><strong>{ "\n Output: "}</strong>{test.output}</>
                                                    :
                                                    ""
                                                }
                                                  </>
                                               
                         
                                                :
                                                null}
                                              
                                            </Typography>                     
                                            </Grid>
                                                                                        
                                                                
                                        </Fragment>
                                    ))}
                                </Grid>
                            </Box>
                      
                    </Box>
                    </Grid>
                </Grid>
                : null}
        </>
    )
}