import React, { useState, useEffect } from 'react';
import { useParams, } from 'react-router-dom';
import { Typography, Container, TextField, Button, Grid, Box, Badge } from '@mui/material';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { CardSkeleton } from '../components/CardSkeleton';
import { CertificateBigIcon, PerfectIcon } from '../components/Icons';

export default function Certificate() {
    const { code } = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>();

    useEffect(() => {
        if (!code) return;
        setLoading(true);
        const token = localStorage.getItem('token');
        MetadataApi.get(`courses/certificate/${code}`,
            {
                headers: ({ Authorization: 'Bearer ' + token }),
            }).then((res: AxiosResponse) => {
                //console.log('getcontent_learn:', res.data)
                setData(res.data)
                setLoading(false)
            }, () => {
                // window.location.href = "/"
            })
    }, [code])

    const [codetext, setCodetext] = useState<string>("")
    const send = () => {
        window.location.href = "/certificate/" + codetext
    }

    return (
        <>
            {code
                ?
                (!loading
                    ?
                    (data
                        ?
                        (data.type === "Course" ?
                            <Container className='centered'>
                                <Typography component="h1" variant="h5" align='center' sx={{ mb: 5 }}>Certificado válido </Typography>
                                <Box justifyContent={'center'} display="flex">
                                    <Grid container direction={'row'} justifyContent={'center'} maxWidth={'md'}
                                        border={1} borderRadius={2} borderColor={'lightgrey'} padding={4} gap={2}>
                                        <Grid item xs={12} md={1} textAlign={'center'}>
                                        <Badge >
                                                <CertificateBigIcon size={70} />
                                                {data.isPerfect?
                                                <PerfectIcon size={27} right={-1} bottom={-7}></PerfectIcon>
                                                 :null}
                                             </Badge>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <Typography variant="body1" align='center'>Curso Finalizado: {data.name}</Typography>
                                            <Typography variant="body1" align='center'>Nombre: {data.firstname} {data.lastname}</Typography>
                                            <Typography variant="body1" align='center'>Fecha de Finalización: {new Date(data.date).toLocaleDateString('es-CL')}</Typography>
                                
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Container> :
                            data.type === "Program" ?
                                <Container className='centered'>
                                    <Typography component="h1" variant="h5" align='center' sx={{ mb: 5 }}>Certificado válido </Typography>
                                    <Box justifyContent={'center'} display="flex">
                                        <Grid container direction={'row'} justifyContent={'center'} maxWidth={'md'}
                                            border={1} borderRadius={2} borderColor={'lightgrey'} padding={4} gap={2}>
                                            <Grid item xs={12} md={1} textAlign={'center'}>
                                            <Badge >
                                                <CertificateBigIcon size={70} />
                                                {data.isPerfect?
                                                <PerfectIcon size={27} right={-1} bottom={-7}></PerfectIcon>
                                                 :null}
                                             </Badge>
                                            </Grid>
                                            <Grid item xs={12} md={7}>
                                                <Typography variant="body1" align='center'>Programa Finalizado: {data.name}</Typography>
                                                <Typography variant="body1" align='center'>Nombre: {data.firstname} {data.lastname}</Typography>
                                                <Typography variant="body1" align='center'>Fecha de Finalización: {new Date(data.date).toLocaleDateString('es-CL')}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Container> : null)
                        :
                        <Container className='centered' maxWidth="xs">
                            <Typography variant="body2" align='center'>Código {code} incorrecto</Typography>
                        </Container>
                    )
                    : <Container className='centered' maxWidth="xs"><CardSkeleton /></Container>
                )
                :
                <Container className='centered' maxWidth="xs">
                    <Typography variant="h6" align='center'>Ingrese código de certificación:</Typography>
                    <TextField margin="normal" required fullWidth
                        id="code1" label="Código" autoFocus
                        onChange={e => setCodetext(e.target.value)}
                    />
                    <Button onClick={send} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={codetext.length === 0}>Ver</Button>
                </Container>

            }

        </>

    )
}