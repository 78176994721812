
import { Skeleton, Fab, Dialog, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';

import { AxiosError, AxiosResponse } from 'axios';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MetadataApi from '../apis/metadata.api';
import { myContext } from '../contexts/Context';
import { ContentStatus, CourseStatus } from '../enums/programs.enums';
import { setContentStatus, setCourseCertificate, setCourseStatus } from '../stores/slices/activeCourseSlice';
import { Box } from '@mui/material'
import { RootState } from '../stores/store';
import MessageDialog from './MessageDialog';
import HelpIcon from '@mui/icons-material/Help';
import { Link } from "react-router-dom";
import JupyterElement from './JupyterElement';
import { TestWindow } from './TestWindow';
import { TestWindowPhone } from './TestWindowsPhone';
import MessageResultDialog from './MessageResultDialog';
import { JupyterPagination } from './JupyterPagination';
import MessageDialogCertificate from './MessageDialogCertificate';
import { ChatBox } from './ChatBox';
import { CourseCountdown } from './CourseCountdown';
import axios,{ CancelTokenSource } from 'axios';

type Props = { contentId: number, openDrawer: boolean,expired:boolean,setCourseTimeRemaining:Function }

const JupyterLesson: React.FC<Props> = ({ contentId, openDrawer,expired,setCourseTimeRemaining }) => {
  const ctx = useContext(myContext);
  const courseState = useSelector((state: RootState) => state.activeCourse);
  const dispatch = useDispatch();
  const [failedLessons, setFailedLessons] = useState<boolean[]>([])
  const [errorTrigger, setErrorTrigger] = useState(0);
  const [errorJupyterTrigger, setErrorJupyterTrigger] = useState(0);
  const [errorJupyterMessage, setErrorJupyterMessage] = useState("");
  const [resultTrigger, setResultTrigger] = useState(0);
  const [certTrigger, setCertTrigger] = useState(0);
  const [perfectCertTrigger, setPerfectCertTrigger] = useState(0);
  const [tests, setTests] = useState<any[]>([])
  const [page, setPage] = useState(1);
  const [actual, setActual] = useState(1);
  const [data, setData] = useState<any>();
  const [lastLessonStatus, setLastLessonStatus] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true);
  const [ShowButtonResponses, setShowButtonResponse] = useState<boolean>(false)
  const [isWindowReponsesOpen, setIsWindowResponsesOpen] = useState(false);
  const [numberNotifications, setNumberNotifications] = useState<number>(0)
  const [isValidating,setIsValidating]=useState<boolean>(false)
  const [score,setScore]=useState<string>("")
  const [openLoadingDialog,setOpenLoadingDialog]=useState<boolean>(false)
  useEffect(() => {
    setIsWindowResponsesOpen(false)
    setShowButtonResponse(false)
  }, [courseState.locationcontent])

  const handleOpenWindow = () => {
    setIsWindowResponsesOpen(!isWindowReponsesOpen);
    setShowButtonResponse(false)
  };
  const handleCloseWindow = () => {
    setIsWindowResponsesOpen(false);
    setShowButtonResponse(true)
  };
  const getNextIndex = (currentIndex: number): number => {
    return (currentIndex + 1) % data.length;
  }

  const getNextIncompleteIndex = () => {
    for (var number = 0; number < data.length; number++) {
      const failedPosition = getNextIndex(page - 1 + number)
      if (data[failedPosition].status === ContentStatus.Failed || data[failedPosition].status === ContentStatus.InProgress || data[failedPosition].status === null) {
        return failedPosition + 1
      }
    }

  }

  const getNextNotPerfectIndex = () => {
    for (var number = 0; number < data.length; number++) {
      const notPerfectPosition = getNextIndex(page - 1 + number)
      if (data[notPerfectPosition].status === ContentStatus.Completed) {
        return notPerfectPosition + 1
      }
    }
  }

  const nextPage = (isCourseCompleted: boolean) => {
    let numberPage: any = 0
    if (isCourseCompleted) {
      numberPage = getNextNotPerfectIndex()
    } else {
      numberPage = getNextIncompleteIndex()
    }
    setPage(numberPage)
    setIsWindowResponsesOpen(false)
    setShowButtonResponse(false)
  }

  const moveArrowPagination = (foward: boolean) => {
    if (foward) {
      setPage(page + 1)
    } else {
      setPage(page - 1)
    }
    setIsWindowResponsesOpen(false)
    setShowButtonResponse(false)
  }

  const moveButtonPagination = (pageNumber: number) => {
    setPage(pageNumber)
    setIsWindowResponsesOpen(false)
    setShowButtonResponse(false)
  }

  useEffect(() => {
    if (!ctx || !ctx.user || ctx.loading) return;
    setLoading(true);
    const token = localStorage.getItem('token');
    const username = ctx.user.username
    MetadataApi.post(`learncontents/getcontentcodelesson`, {
      contentId: contentId,
      username: username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      setData(res.data)
      let progress = 1
      let newFailedLessons: boolean[] = []
      res.data.forEach((code_lesson: any) => {
        const status = code_lesson.status;
        if (courseState.course.status === CourseStatus.Completed) {
          newFailedLessons.push(status === ContentStatus.Perfect ? false : status === ContentStatus.Completed);
        } else {
          //this is to know what would be the last position of "actual", where actual is the last page you can move inside the content
          if (status !== null) {
            progress++
          }
          //this is to know the positions of the lessons with status failed/inprogress, marked with a true
          newFailedLessons.push(
            status === ContentStatus.Completed || status === ContentStatus.Perfect
              ? false
              : status === ContentStatus.Failed || status === ContentStatus.InProgress
          );
        }

      });
      setFailedLessons(newFailedLessons)
      const indexOfTrue = newFailedLessons.indexOf(true);
      const lastIndexOfTrue = newFailedLessons.lastIndexOf(true)
      if (courseState.course.status === CourseStatus.Completed) {
        if (indexOfTrue !== -1) {
          setPage(indexOfTrue + 1)
          setActual(res.data.length)
        } else {
          if (progress > res.data.length) progress--
          setPage(res.data.length)
          setActual(res.data.length)
        }
      } else {
        if (indexOfTrue !== -1) {
          setPage(indexOfTrue + 1)
          if (res.data[lastIndexOfTrue + 1] && res.data[lastIndexOfTrue + 1].status === null) {
            setActual(lastIndexOfTrue + 1)
          } else {
            setActual(progress)
          }
        } else {
          if (progress > res.data.length) progress--
          setPage(progress)
          setActual(progress)
        }
      }
      setLoading(false)
    })
  }, [ctx, contentId, courseState.course.status])



  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  const handleCloseLoadingDialog=()=>{
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Operación cancelada por el usuario');
    }
    setOpenLoadingDialog(false)
    setIsValidating(false)
  }
  const handleSubmit = (canValidate: boolean) => {
    
    if (!ctx || !ctx.user) return;
    setIsValidating(true)
    setShowButtonResponse(false)
    setIsWindowResponsesOpen(false)
    setOpenLoadingDialog(true)
    cancelTokenRef.current = axios.CancelToken.source();
    const token = localStorage.getItem('token');
    MetadataApi.post("learncontents/validatejupyter", {
      contentId: contentId,
      username: ctx.user?.username,
      courseId: courseState.course.courseId,
      chapterId: courseState.content.chapterId,
      jupyterId: data[page - 1].id,
    }, {
      cancelToken:cancelTokenRef.current.token,
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      if (res.data.status==="Ok") {
    
        updateScore(res.data.arrayTests.results)
  
        let updated_data = [...data]
        //this removed the "true" from newfailedlessons array
        updated_data[page - 1] = { ...updated_data[page - 1], status: res.data.jupyterStatus }
        let newFailedLessons = [...failedLessons]
        if (res.data.jupyterStatus === ContentStatus.Perfect || res.data.jupyterStatus === ContentStatus.Completed) {
          newFailedLessons[page - 1] = false
        } else if (res.data.jupyterStatus === ContentStatus.Failed) {
          newFailedLessons[page - 1] = true
        }

      //Only if you are at the page where is the last page enabled, add 1 to enable the next one 
        if (res.data.jupyterStatus === ContentStatus.Perfect || res.data.jupyterStatus === ContentStatus.Completed) {
          if (actual === page) {
            setActual(actual + 1)
          }
        }
        setFailedLessons(newFailedLessons)
        setData(updated_data)
        if (canValidate) {
          setTests(res.data.arrayTests.results)
          setShowButtonResponse(false)
          setIsWindowResponsesOpen(true)
        }
        dispatch(setContentStatus(res.data.contentStatus));
        dispatch(setCourseStatus(res.data.contentData.course.courseStatus));
        //caso donde ya existe el certificado complete y se obtiene el perfect

        if (res.data.contentData.course.courseCertificate && res.data.contentData.course.courseCertificate !== '') {
          dispatch(setCourseCertificate(res.data.contentData.course.courseCertificate));

          if (res.data.contentData.course.isPerfect) { //caso donde llega perfect
            setPerfectCertTrigger((perfectCertTrigger) => perfectCertTrigger + 1);
          } else {
            setCertTrigger((certTrigger) => certTrigger + 1);
          }

        }else {
          if (canValidate) {
            setResultTrigger((resultTrigger) => resultTrigger + 1);
            setLastLessonStatus(res.data.jupyterStatus)
          } else {
            if (page === data.length) {
              setResultTrigger((resultTrigger) => resultTrigger + 1);
            } else {
              const numberPage = page + 1
              setPage(numberPage)
            }
          }
        }
      }else if(res.data.status==="Error"){
        setErrorJupyterTrigger((errorJupyterTrigger)=>errorJupyterTrigger+1)
        setErrorJupyterMessage(res.data.error)
      }
      setOpenLoadingDialog(false)
      setIsValidating(false)
    }).catch((error:AxiosError) => {
      if (axios.isCancel(error)) {
        setOpenLoadingDialog(false)
      } else {
        setOpenLoadingDialog(false)
        setErrorTrigger(errorTrigger => errorTrigger + 1);
      }
  })};
  const updateScore=(tests:any)=>{
    let numberCorrects=0
    const totalTests=tests.length
    tests.forEach((test:any) => {
      if(test.result===1) numberCorrects++
    });
    setScore(numberCorrects+" de "+ totalTests+ " correctas")
  }
  const getNumberNotifications = useCallback((page: number) => {
    if(data){
      const token = localStorage.getItem('token');
      MetadataApi.post("learncomments/getchatnumbernotifications", {
        courseId: courseState.course.courseId,
        contentId: data[page - 1].contentId,
        username: ctx?.user?.username,
        codelessonId: data[page - 1].id,
      }, {
        headers: ({
          Authorization: 'Bearer ' + token
        }),
      }).then((res: AxiosResponse) => {
        setNumberNotifications(res.data)
      })
  
    }
    

  }, [courseState.course, ctx.user, data])

  useEffect(() => {

      getNumberNotifications(page)

  }, [getNumberNotifications,page])



  return (
    <>
      <MessageResultDialog trigger={resultTrigger} contentState={courseState.content.status} nextPage={nextPage} lastLessonStatus={lastLessonStatus} score={score} />
      <MessageDialog trigger={errorJupyterTrigger} message={errorJupyterMessage} />
      <MessageDialog trigger={errorTrigger} message={'Error al guardar en Base de Datos'} />

      <MessageDialogCertificate trigger={certTrigger} message={'Felicidades, has finalizado el curso y obtenido tu certificado, puede intentar terminar el curso con estado Perfecto completando todos los ejecicios perfectamente.'} isPerfect={false} />
      <MessageDialogCertificate trigger={perfectCertTrigger} message={'Felicidades, has finalizado el curso de manera perfecta y obtenido tu certificado '} isPerfect={true} />

   
      {!loading ?
      <>
      {courseState.course.hours_limit ? (
              <Box
                sx={{ mt: 2, position: "absolute", right: 30, display: { xs: "none", md: "flex" }}}
              >
                <CourseCountdown course_hours={courseState.course.hours} start_date={courseState.course.start_date} isLearn={true} setCourseTimeRemaining={setCourseTimeRemaining} />
              </Box>
          ) 
          :  
          <ChatBox
          contentId={data[page - 1].contentId} codelessonId={data[page - 1].id} numberNotifications={numberNotifications} setNumberNotifications={setNumberNotifications} getNumberNotifications={getNumberNotifications} page={page} multiplesChats={true}
        />}
      <Link to="/help/JupyterLesson" target={"_blank"} >
        <br />
        <Fab size="small" color="secondary" aria-label="add" sx={{ position: "absolute", right: 16 }}> <HelpIcon /> </Fab>
      </Link>
       <Grid container  spacing={2} p={3} justifyContent="center" >
          <Grid item xs={12} md={5}  >
            <Typography variant='h5' sx={{ textAlign: { xs: 'center', md: 'left' } }}>{data[0].content_name}</Typography>
          </Grid>
          <Grid item  xs={12} md={5} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                 <JupyterPagination data={data} actualLastPage={actual} actualPage={page} moveArrowPagination={moveArrowPagination} moveButtonPagination={moveButtonPagination} ></JupyterPagination>
          </Grid>
          <Grid item md={2} sx={{ display: { xs: "none", md: "flex" } }} />
          {courseState.course.hours_limit ? (
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <CourseCountdown course_hours={courseState.course.hours} start_date={courseState.course.start_date} isLearn={true} setCourseTimeRemaining={setCourseTimeRemaining} />
            </Box>
                
          ) : null}
         
          <Grid item xs={12}>
            {data.map((item: any, index: number) => {
              return (
                <Box key={index} hidden={(index + 1) !== page}>
                  <JupyterElement item={item} handleSubmit={handleSubmit} expired={expired} isValidating={isValidating} />
                </Box>
              )
            })}
          </Grid>
          <Grid item md={12} sx={{ display: { xs: 'none', md: 'flex' }}} >
            <TestWindow openDrawer={openDrawer} ShowButtonResponses={ShowButtonResponses} handleOpenWindow={handleOpenWindow} isWindowReponsesOpen={isWindowReponsesOpen} handleCloseWindow={handleCloseWindow} tests={tests}></TestWindow>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} sx={{ marginLeft: 2, width: "100%", display: { xs: 'flex', md: 'none', sm: "flex" }, }} >
              <TestWindowPhone isWindowReponsesOpen={isWindowReponsesOpen} tests={tests}></TestWindowPhone>
            </Grid>
          </Grid>
        </Grid>
      </>
       
        :
        <Skeleton></Skeleton>
      }
      {<Dialog open={openLoadingDialog} maxWidth="xs" fullWidth >
       <DialogContent>
          <Typography align='center' variant='h6'>Validando </Typography>
          <Typography align='center' sx={{mt:2}}><CircularProgress size={30} ></CircularProgress></Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoadingDialog} color="primary" size='small'>
           Cancelar
          </Button>
        </DialogActions>
      </Dialog>}
    </>
  )
}

export default JupyterLesson;