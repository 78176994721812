import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { myContext } from '../contexts/Context';
import HomePage from '../pages/HomePage';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import Register from '../pages/Register';
import MyHome from '../pages/MyHome';
import Learn from '../pages/Learn';
import Program from '../pages/Program';
import ProgramDetail from '../pages/ProgramDetail';
import Payment from '../pages/Payment';
import HelpPage from '../pages/HelpPage';
import HelpQuestions from '../pages/Help/HelpQuestions';
import Certificate from '../pages/Certificate';
import Course from '../pages/Course';
import CourseDetail from '../pages/CourseDetail';
import { removedSession } from '../common/SessionRewindMethods';
import { useDispatch, useSelector } from 'react-redux';
import { closeFilm } from '../stores/slices/sessionRewindSlice';
import { RootState } from '../stores/store';
import { Notifications } from '../pages/Notifications';

export default function Routing () {
    const ctx = useContext(myContext);
    const dispatch = useDispatch();
    const location=useLocation()
    const sessionRewind = useSelector((state: RootState) => state.sessionRewind);
    useEffect(() => {
        
    const route=location.pathname.split("/")[1]

    if(route!=="learn"&&sessionRewind.isFilming){
      removedSession()
      dispatch(closeFilm())
    }
  }, [location.pathname,dispatch,sessionRewind.isFilming]);
    return (
        <>
            <Routes>
                <Route path="/" element={<HomePage/>}></Route>
                <Route path="/program" element={<Program/>}></Route>
                <Route path="/program/:id" element={<ProgramDetail/>}></Route>
                <Route path="/course" element={<Course/>}></Route>
                <Route path="/course/:id" element={<CourseDetail/>}></Route>
                <Route path="/help" element={<HelpPage/>}></Route>
                <Route path="/help/:typequestion" element={<HelpQuestions/>}></Route>
                <Route path="/certificate" element={<Certificate/>}></Route>
                <Route path="/certificate/:code" element={<Certificate/>}></Route>
                
                { ctx && ctx.user && Object.keys(ctx.user).length !== 0 ? (
                <>
                    <Route path="/myhome" element={<MyHome/>}></Route>
                    <Route path="/profile" element={<Profile/>}></Route>
                    <Route path="/learn/:id" element={<Learn/>}></Route>
                    <Route path="/learn/:id/:contentId" element={<Learn/>}></Route>
                    <Route path="/learn/:id/:contentId/:codelessonId" element={<Learn/>}></Route>
                    <Route path="/payment/:type/:id" element={<Payment/>}></Route>
                    <Route path="/notifications" element={<Notifications/>}></Route>
                 </>
                ) : (
                <>
                        <Route path="/login" element={<Login/>}></Route>
                        {process.env.REACT_APP_REGISTER_ENABLED==='TRUE' ?
                            <Route path='/register' element={<Register/>}></Route>  
                            : <></>
                    }
                </>  
                )
                }

            </Routes>
        </>
    );
}