import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { myContext } from '../contexts/Context';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import CourseCard from '../components/CourseCard';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { CardSkeleton } from '../components/CardSkeleton';
import ProgramCard from '../components/ProgramCard';
import CourseCardSimple from '../components/CourseCardSimple';
import { Link } from 'react-router-dom';
export default function MyHome() {
  const ctx = useContext(myContext);
  const courseState = useSelector((state: RootState) => state.activeCourse);

  const [coursedata, setCourseData] = useState<any>([]);
  const [loadingCourse, setLoadingCourse] = useState<boolean>(true);
  const [programdata, setProgramData] = useState<any>([]);
  const [loadingProgram, setLoadingProgram] = useState<boolean>(true);

  useEffect(() => {
    if (!ctx || !ctx.user) return;
    setLoadingCourse(true);
    setLoadingProgram(true);
    const token = localStorage.getItem('token');
    MetadataApi.post(`/learncourses/getactivecourses`, {
      username: ctx.user.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    })
      .then((res: AxiosResponse) => {
        //console.log('getactivecourses',res.data)
        setCourseData(res.data);
        setLoadingCourse(false);
      })
    MetadataApi.post(`/learnprograms/getactiveprograms`, {
      username: ctx.user.username
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      })
    })
      .then((res: AxiosResponse) => {
        //console.log('getactiveprograms',res.data)
        setProgramData(res.data);
        setLoadingProgram(false);
      })
  }, [ctx]);

  return (
    <Container className='centered'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {ctx && ctx.user && Object.keys(ctx.user).length !== 0 ?
            <Typography variant='h4' alignItems='center'>Hola, {ctx.user.firstname}!</Typography>
            : ''}
        </Grid>

        {courseState.course ?
          <>
            <Grid item xs={12}>
              <Typography variant='h5' alignItems='center'>Sigue con tu avance:</Typography>
            </Grid>
            <CourseCardSimple courseId={courseState.course.courseId} />
          </>
          : <></>
        }
        <>
        <Grid container sx={{marginLeft:2,marginTop:2}}>
          <Grid item >
          <Typography variant='h5'>Ver todos:</Typography>
          </Grid>
          <Grid item > 
            <Button component={Link} to={"/course?myhome=mycourses"} sx={{marginLeft:2}}  size='small' variant='outlined'>Mis Cursos</Button>
          </Grid>
          <Grid item >
            <Button component={Link} to={"/program?myhome=myprograms"}  sx={{marginLeft:2}} size='small' variant='outlined'>Mis Programas</Button>
          </Grid>
        </Grid>
        
        </>
        {coursedata.length > 0 ? <>

          <Grid item xs={12} height={60}>
            <Typography variant='h5' alignItems='center'>Cursos en Progreso:
            </Typography>
          </Grid>
          {!loadingCourse ?
            <Grid container spacing={2}>
              {coursedata.map((item: any, index: number) => {
                return (
                  <Grid key={index} item xs={12} sm={6}>
                    <CourseCard courseId={item.courseId} />
                  </Grid>
                )
              })
              }
            </Grid>
            :
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}><CardSkeleton /></Grid>
              <Grid item xs={12} sm={6}><CardSkeleton /></Grid>
            </Grid>

          }
        </> : <></>}

        {programdata.length > 0 ? <>
          <Grid item xs={12} height={60}>
            <Typography variant='h5' alignItems='center'>Programas en Progreso:</Typography>
          </Grid>
          {!loadingProgram ?
            <Grid container spacing={2}>
              {programdata.map((item: any, index: number) => {
                return (
                  <Grid key={index} item xs={12} sm={6}>
                    <ProgramCard programId={item.programId} />
                  </Grid>
                )
              })
              }
            </Grid>
            :
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}><CardSkeleton /></Grid>
              <Grid item xs={12} sm={6}><CardSkeleton /></Grid>
            </Grid>

          }
        </> : <></>}

      </Grid>
    </Container>
  )
}