import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import Routing from './routing/Routing';
import './components/styles/editor.css'
import AlertBar from './components/AlertBar';
function App() {
  return (
    <BrowserRouter>
    <AlertBar/>
      <NavBar/>
      <Routing/>
    </BrowserRouter>
  );
}

export default App;
