import  { ReactNode, createContext, useContext, useEffect, useState} from 'react'
import {io,Socket} from 'socket.io-client'
import { myContext } from './Context';
const isWebSocketEnabled = process.env.REACT_APP_WEBSOCKET_ENABLED === 'TRUE';
const socketUrl = process.env.REACT_APP_SOCKET_API || 'http://localhost:4001';

export const WebSocketContext = createContext<Socket | undefined>(undefined);
interface WebSocketProviderProps {
  children: ReactNode;
}
export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const ctx = useContext(myContext);
  const [socket, setSocket] = useState<Socket | undefined>(undefined);

  useEffect(() => {
    if (isWebSocketEnabled && ctx && Object.keys(ctx).length !== 0) {
      const newSocket = io(socketUrl);
      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [ctx]);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};