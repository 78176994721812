import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import { setContent } from '../stores/slices/activeCourseSlice';
import { ContentStatus } from '../enums/programs.enums';
import { List } from '@mui/material';
import { Box } from '@mui/system';
import { ListSeparator } from './ListSeparator';
import { ListContents } from './ListContents';
import { ListChapter } from './ListChapter';
import { useParams } from 'react-router-dom';


export function ListStepper(props: any) {
  const {contentId } = useParams();
  const courseState = useSelector((state: RootState) => state.activeCourse);
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  var indexActive: number = 0;
  const [activeItem, setActiveItem] = useState<string>("")
  const [dataLength, setDataLength] = useState<number>(0)
  const [isLoaded,setIsLoaded]=useState<Boolean>(false)
  const listItemRefs = useMemo(() => {
    return courseState.course.chapters.map((chapter: any) => ({
      contentRefs: chapter.contents.map(() => React.createRef<any>())
    }));
  }, [courseState.course.chapters]);

  const scrollIntoView = useCallback((indexChapter: number, indexContent: number) => {
    const innerRef = listItemRefs[indexChapter]?.contentRefs[indexContent];
    if (innerRef) {
      innerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      
    }
  }, [listItemRefs])

  const handleClick = (indexChapter: number, indexContent: number, contentId: number) => {
    scrollIntoView(indexChapter, indexContent)
    setActiveItem(`${indexChapter}-${indexContent}`)
    dispatch(setContent(contentId.toString()))
    const newListRef: any = []
    listItemRefs.forEach((chapter: any) => {
      chapter.contentRefs.forEach((ref: any) => {
        const currentRef = ref.current
        newListRef.push(currentRef)
      })
    })
  }

  useEffect(() => {
    if(isLoaded){
      scrollIntoView(courseState.locationchapter, courseState.locationcontent)
      setActiveItem(`${courseState.locationchapter}-${courseState.locationcontent}`)
    }
   
  }, [courseState.locationchapter, courseState.locationcontent, scrollIntoView,isLoaded])

  useEffect(() => {
    if(!isMounted.current&&!contentId&&isLoaded){
      let completed = true
      for (let i = 0; i < courseState.course.chapters.length; i++) {
        for (let j = 0; j < courseState.course.chapters[i].contents.length; j++) {
          let contentStatus = courseState.course.chapters[i].contents[j].status
          if (contentStatus === ContentStatus.Failed || contentStatus === ContentStatus.InProgress || contentStatus === ContentStatus.ToDo) {
            setActiveItem(`${i}-${j}`)
            scrollIntoView(i, j)
            dispatch(setContent(courseState.course.chapters[i].contents[j].contentId))
           
            completed = false
            break
          }
        }
        if (!completed) {
          break
        }
      }
      if (completed) {
        setActiveItem(`${0}-${0}`)
        scrollIntoView(0, 0)
      }
      isMounted.current=true
    }
   
  }, [courseState.course, dispatch, scrollIntoView,isMounted,contentId,isLoaded])

  useEffect(() => {
    if(!isLoaded){
      let allLength = 0;
    for (let index1 = 0; index1 < courseState.course.chapters.length; index1++) {
      allLength++;
      for (let index2 = 0; index2 < courseState.course.chapters[index1].contents.length; index2++) {
        allLength++;
      }
    }
    setDataLength(allLength);
    setIsLoaded(true)  
  }
    
  }, [courseState.course,isLoaded]);

  return (
    <>
     {isLoaded? <Box sx={{
      overflow: 'auto', height: '90%', '&::-webkit-scrollbar': {
        width: 0
      }
    }}>
      <List sx={{ height: '100%' }}>
        {courseState.course.chapters.map((chapter: any, indexChapter: number) => {
          const contentRefs = listItemRefs[indexChapter]?.contentRefs;
          let currentIndex = indexActive++;
          return (
            <Fragment key={"chapter:" + indexChapter} >
              <ListChapter indexChapter={indexChapter} openDelay={props.openDelay} chapter={chapter}></ListChapter>
              {currentIndex<dataLength-1 ? <ListSeparator /> : null}
              {chapter.contents.map((content: any, indexContent: number) => {
                const contentRef = contentRefs[indexContent];
                let currentIndex = indexActive++;
                return (
                  <Fragment key={"Contenido:" + indexChapter + "_" + indexContent} >
                    <ListContents indexChapter={indexChapter} indexContent={indexContent} handleClick={handleClick} content={content} contentRef={contentRef} activeItem={activeItem} openDelay={props.openDelay}></ListContents>
                    {currentIndex<dataLength-1 ? <ListSeparator /> : null}
                  </Fragment>
                )
              })}
            </Fragment>
          )
        })}
      </List>
    </Box>:null}
    </>
   
   
  )
}