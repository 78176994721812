import { createSlice, PayloadAction } from '@reduxjs/toolkit'

  export interface SessionState {
    alertOpen: boolean
  }

  const initialState: SessionState = {
    alertOpen: false,
  }

  export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
      alertDialogOpen:(state,action: PayloadAction<boolean>)=>{
        state.alertOpen=action.payload
    },

    },
  })

  export const { alertDialogOpen } = alertSlice.actions

  export default alertSlice.reducer