import { Typography, ListItem, ListItemText, Box } from '@mui/material';
import { Role } from '../enums/roles.enums';
import { ChatMessage } from '../enums/chatStatus.enum';
import * as DOMPurify from 'dompurify';
type Props = {
    index: number,
    comment: any
}
export function Chat({ index, comment }: Props) {
    const cleanData = (html: string) => {
        return DOMPurify.sanitize(html)
    }
    const changeDateFormat = (date: Date) => {
        if (date) {
            const dateObject = new Date(date);
            const day = dateObject.getDate().toString().padStart(2, '0');
            const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
            const hours = dateObject.getHours().toString().padStart(2, '0');
            const minutes = dateObject.getMinutes().toString().padStart(2, '0');

            const formattedDate = `${day}/${month} ${hours}:${minutes}`;
            return formattedDate
        } else {
            return " "
        }


    }

    return (
        <>

            {comment.comment === ChatMessage.Endend ? <Typography color={"gray"} align='center' >
                {ChatMessage.Endend}</Typography> :
                comment.comment === ChatMessage.Taked ? <Typography  color={"gray"} align='center' >
                    {ChatMessage.Taked}</Typography> :
                     comment.comment === ChatMessage.Reactivate ? <Typography  color={"gray"} align='center' >
                     {ChatMessage.Reactivate}</Typography> :
                    <ListItem sx={{ width: "100%" }}
                        key={index}
                    >

                        <Box sx={{
                            backgroundColor: comment.user_type === Role.TeacherAssistant ? 'white' : 'primary.light',
                            borderRadius: '10px',
                            padding: '10px',
                            maxWidth: '80%',
                            marginLeft: comment.user_type === Role.TeacherAssistant ? '0' : 'auto',
                        }}>
                            <ListItemText
                                disableTypography

                                primary={

                                    <Typography
                                        key={"name" + index}
                                        variant="body2"
                                        color="text.primary"

                                        align={comment.user_type === Role.TeacherAssistant ? "right" : "left"}
                                    >
                                        {comment.user_type === Role.TeacherAssistant ?
                                         comment.user_comment==="BOT"?
                                         "BOT"
                                         : "Profesor:" 
                                         : ""} {changeDateFormat(comment.date)}
                                    </Typography>


                                }
                                secondary={
                                    <>
                                        <Typography
                                            key={"comment" + index}
                                            variant="body1"
                                            color="text.primary"
                                            sx={{
                                                width: "inherit",
                                                overflowWrap: 'break-word', // Add this property to enable text wrapping
                                            }}
                                            align={comment.user_type === Role.TeacherAssistant ? "right" : "left"}
                                            dangerouslySetInnerHTML={{ __html: cleanData(comment.comment) }}
                                        >

                                           {/*  {comment.comment} */}
                                        </Typography>


                                    </>
                                }
                            />
                        </Box>
                    </ListItem>
            }


        </>
    )
}