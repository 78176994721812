import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type notificationProps = {
    contentId: number,
    comment: string, //can be usefull
    content_name: string,
    course_name:string,
    codelessonId:number,
    date:Date,
    type:string
}

export interface NotificationState {
    notifications: notificationProps[],
    chatOpen:boolean,
}

const initialState: NotificationState = {
    notifications: [],
    chatOpen:false
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        getAllNotification: (state, action: PayloadAction<notificationProps[]>) => {
            state.notifications = action.payload
        },
        addNotification: (state, action: PayloadAction<notificationProps>) => {
         
            state.notifications.push(action.payload)
        },
        removeNotificationByIds: (state, action: PayloadAction<any>) => {
            const { contentId,codelessonId,type } = action.payload;
            for(let i=0;i<state.notifications.length;i++){

                if(state.notifications[i].contentId===contentId&&state.notifications[i].codelessonId===codelessonId &&state.notifications[i].type===type ){
                    state.notifications.splice(i,1)
                }
            }
        },
        OpenChat:(state,action: PayloadAction<boolean>)=>{
            state.chatOpen=action.payload
        },
        
    },
})

export const { addNotification,getAllNotification,OpenChat,removeNotificationByIds } = notificationSlice.actions

export default notificationSlice.reducer