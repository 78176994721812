import { Dialog, DialogContent, DialogTitle, Fab, Typography, Button, TextField, DialogContentText, Box, Grid } from '@mui/material';
import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded';
import { useContext, useEffect, useState } from 'react';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
/* import { Wysiwyg } from './Wysiwyg'; */
import { RootState } from '../stores/store';
import { useSelector, useDispatch } from 'react-redux';
import { myContext } from '../contexts/Context';
import { ContentStatus } from '../enums/programs.enums';
import { setContentStatus } from '../stores/slices/activeCourseSlice';
type Props = {
    contentId: number,
    contentTitle: string,
    isJupyterLoaded: boolean,
}
const ProjectDialog: React.FC<Props> = ({ contentId, contentTitle, isJupyterLoaded }) => {
    const ctx = useContext(myContext);
    const [open, setOpen] = useState<boolean>(false)
    /* const [editorValue, setEditorValue] = useState<string>("<p><br></p>"); */
    const [sendData, setSendData] = useState<any>({
        fileName: "",
        comment: ""

    })
    /* const [fileName, setFileName] = useState<string>("") */
    const [fabDisabled, setFabDisabled] = useState<boolean>(false)
    const [sendMessage, setSendMessage] = useState<string>("Enviar")
    //Valor true cuando vuelva el wysiwyg
    /* const [disabledSend,setDisabledSend] = useState<boolean>(false) */
    const courseState = useSelector((state: RootState) => state.activeCourse);
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleInputChange = (e: any) => {
        setSendData(
            {
                ...sendData, [e.target.name]: e.target.value
            }
        )
        /* setFileName(e.target.value) */
    }
    const dispatch = useDispatch();
    const sendProject = (e: any) => {
        e.preventDefault()
        const token = localStorage.getItem('token');
        MetadataApi.post("learncomments/addusercomments", {
            contentId: contentId,
            username: ctx.user?.username,
            comment: sendData.comment,
            file: sendData.fileName,
            user_type: "User",
            courseId: courseState.course.courseId,
            newStatus: ContentStatus.Pending
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            }),
        }).then((res: AxiosResponse) => {
        
            dispatch(setContentStatus(ContentStatus.Pending));
            handleClose()
            setSendMessage("Enviado")
            setFabDisabled(true)

        })
    }
    useEffect(() => {
        if (courseState.content.status === ContentStatus.ToDo || courseState.content.status === ContentStatus.InProgress || courseState.content.status === ContentStatus.Failed) {
            setFabDisabled(false)
        } else {
            setFabDisabled(true)
            setSendMessage("Enviado")
        }
    }, [courseState])

    /*  useEffect(() => {
       if(editorValue==="<p><br></p>"){
         setDisabledSend(true)
       }else{
         setDisabledSend(false)
       }
     }, [editorValue]) */

    return (
        <>  {isJupyterLoaded ?
            <Grid position={"relative"}>


            <Fab disabled={fabDisabled} onClick={handleOpen} variant='extended' size="small" color="primary" aria-label="add"
                sx={{ position: "absolute", bottom: 25, right: 50 }}>
                <PlaylistPlayRoundedIcon fontSize='large' /> <Typography variant='body2' sx={{ mr: 1 }}>{sendMessage} </Typography>
            </Fab>             </Grid>: null}

            {<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Envio de proyecto {contentTitle}</DialogTitle>
                <DialogContentText marginLeft={3} marginRight={3} align='justify' variant='body1'>
                    A continuación debera escribir el nombre del JupyterNotebook a entregar y los comentarios indicando el avanze realizado. Luego de la entrega se procedera a entrar a un periodo de espera, lo cual no le permitira seguir a la siguiente lección hasta que un profesor le apruebe o rechaze el desarrollo del contenido actual.
                </DialogContentText>
                <DialogContent>
                    <form onSubmit={sendProject}>
                        <TextField
                            required
                            margin="dense"
                            id="fileName"
                            name="fileName"
                            label="Nombre del JupyterLesson a entregar"
                            type="text"
                            value={sendData.fileName}
                            onChange={handleInputChange}
                            fullWidth
                            variant="standard"
                        />

                        <TextField

                            required
                            margin="dense"
                            id="comment"
                            name="comment"
                            label="Comentario"
                            type="text"
                            multiline
                            value={sendData.comment}
                            onChange={handleInputChange}
                            fullWidth
                            variant="standard"
                        />
                        {/* <Typography variant='body1' sx={{ marginTop: 2 }}> Comentario*</Typography>
                    <Wysiwyg height={250} value={editorValue} change={setEditorValue} courseId={courseState.course.courseId}></Wysiwyg> */}

                        <Box sx={{ textAlign: "right" }} >
                            <Button onClick={handleClose} variant='outlined' sx={{mr:1}}>Cancelar</Button>
                            <Button type='submit' onClick={sendProject} variant='contained'>Enviar</Button>
                        </Box>

                    </form>

                </DialogContent>

            </Dialog>}
        </>

    )
}

export default ProjectDialog;