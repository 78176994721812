import React, { useEffect, useState, useContext } from 'react';
import CourseList from '../components/CourseList';
import {Container,Typography,Button,Box,Link as ShortLink} from '@mui/material';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { Link } from 'react-router-dom';
import { CardSkeleton } from '../components/CardSkeleton';
import { myContext } from '../contexts/Context';
import { ProgramStatus } from '../enums/programs.enums';
import { ProgramProgress } from '../components/ProgramProgress';
import ProjectSelectDialog from '../components/ProjectSelectDialog';
import { CertificateIcon } from '../components/Icons';

export enum ProjectStatusEnum {
    No = 'No',
    Yes = 'Yes',
    Selectable = 'Selectable',
}
export const projectStatus = (program:any) => {
    if(!program) return ProjectStatusEnum.No;
    const existprojects = program.programcourses.filter((item:any)=>{return !item.course || item.course.isProject})
    if(existprojects.length===0) return ProjectStatusEnum.No;
    const haveprojects = program.programcourses.filter((item:any)=>{return item.course && item.course.isProject})
    if(haveprojects.length>0) return ProjectStatusEnum.Yes;
    return ProjectStatusEnum.Selectable;
};
  
export default function ProgramDetail () {
    const ctx = useContext(myContext);

    const { id } = useParams();
    const navigate = useNavigate();
	const goBack = () => {navigate(-1);}

    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [projectTrigger, setProjectTrigger] = useState(0);

    const handleProjectClick = () => {
        setProjectTrigger((projectTrigger) => projectTrigger + 1);
    };

    useEffect(() => {
        if(ctx.loading) return;
        setLoading(true);
        if(ctx && ctx.user){
            const token = localStorage.getItem('token');
            //validate if user have the program
            MetadataApi.post(`learnprograms/validateprogram`,
                { programId: id, username: ctx.user.username },
                { headers: ({ Authorization: 'Bearer ' + token }) }
                ).then((res: AxiosResponse) => {

                //console.log('validateprogram', res.data)
                if (!res.data) {
                    //if not, then we get general program data
                    MetadataApi.get(`programs/getprogram/${id}/data`, {
                    }).then((res: AxiosResponse) => {
                        //console.log('getprogram',res.data)
                        setData(res.data)
                        setLoading(false)
                    }, () => {
                        window.location.href = "/"
                    })
                } else if (ctx.user) {
                    //if user have the program, ask for user details
                    MetadataApi.post(`learnprograms/getprogram`, {
                        programId: id,
                        username: ctx.user.username
                    }, {
                        headers: ({
                            Authorization: 'Bearer ' + token
                        }),
                    }).then((res: AxiosResponse) => {
                        console.log('getcourse_learn:',res.data)
                        setData(res.data)
                        setLoading(false)
                    }, () => {
                        window.location.href = "/"
                    })
                }
            })

        } else {
            MetadataApi.get(`programs/getprogram/${id}/data`, {
            }).then((res : AxiosResponse) => {
                //console.log('getprogram',res.data)
                setData(res.data)
                setLoading(false)
            }, () => {
                window.location.href = "/"
            })
        }
    },[ctx,id]);

    return (
        <Container className='centered'>
            <ProjectSelectDialog trigger={projectTrigger} programId={data?data.programId:-1} />
            <IconButton onClick={goBack}>
              <ChevronLeftIcon/>
            </IconButton>
            <Grid container spacing={2} justifyContent="center" pb={5}>
                <Grid item xs={12}>
                    <Typography variant='h4' align='center'>{!loading ? `${data.name}` : <Skeleton/> }</Typography>
                </Grid>
                <Grid item xs={12}>    
                    <Typography variant='body1' align='center' sx={{ whiteSpace: 'pre-line'}}>
                        {!loading ? `${data.Description}` 
                        : <Skeleton height={200}/> }
                    </Typography>
                    {!loading ? <Grid>
                    <Typography variant='body1' component={"div"} align='center' sx={{ whiteSpace: 'pre-line'}}>
                    Tiempo: <Box sx={{fontWeight:"bold",display:"inline"}}>{data.hours}</Box>
                     </Typography>
                    </Grid>:<Skeleton height={200}/>
                    }
                    
                   
                </Grid>
                {data && data.certificate?<Grid item xs={12}><Typography align='center'><CertificateIcon size={50} url={data.certificate.code} /></Typography></Grid>:null}
                {!loading && data.professor_name && data.professor_name.length>1 && data.professor_link && data.professor_link.length>1 ?
                    <Grid item xs={12} alignItems={'center'}>
                        <Typography variant='body2' align='center'>Realizado por: <ShortLink href={data.professor_link} target='_blank'>{data.professor_name}</ShortLink></Typography>
                    </Grid>
                :
                    <></>
                }
                {!loading && data.status===ProgramStatus.InProgress ?
                    <Grid item xs={12} alignItems={'center'}>
                        <Box display="flex" justifyContent="center">
                            <ProgramProgress width='100%' data={data}></ProgramProgress>
                        </Box>
                    </Grid>
                :
                    <></>
                }
                <Grid item>    
                    {!loading ?
                        data.status ?
                            <Button variant={data.status===ProgramStatus.Completed?'outlined':'contained'} component={Link} to={"/learn/"+data.programcourses[0].courseId}>{data.status===ProgramStatus.ToDo ?'Iniciar programa':(data.status===ProgramStatus.InProgress ?'Continuar programa':'Programa Completado')}</Button>
                        :
                        process.env.REACT_APP_SUBSCRIBE_ENABLED==='TRUE' ?
                        <Button size='small' variant="contained" component={Link} to={"/payment/course/" + data.courseId}>Inscríbete por USD${data.listPrice}.-</Button> 
                : null 
                    :
                        <Skeleton><Button/></Skeleton>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5' align='center'>{!loading ? `Cursos del Programa` : <Skeleton/> }</Typography>
                </Grid>
                <Grid item xs={12} sm={10} md={8} lg={6}>
                {!loading ?  
                    <CourseList courses={data.programcourses.filter((item:any)=>{return item.course && !item.course.isProject})}/>
                :   <CardSkeleton/>
                }
                </Grid>
                {!loading && projectStatus(data)!==ProjectStatusEnum.No ?  
                    <Grid item xs={12}>
                        <Typography variant='h5' align='center'>{!loading ? `¡Selecciona un proyecto!` : <Skeleton/> }</Typography>
                    </Grid>
                    : <Grid item xs={12} height={0}></Grid>
                }
                {!loading && ctx && projectStatus(data)===ProjectStatusEnum.Selectable ?  
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Button variant="contained" onClick={handleProjectClick}>Seleccionar</Button>
                        </Box>
                    </Grid>
                    : <></>
                }
                <Grid item xs={12} sm={10} md={8} lg={6}>
                {!loading ?  
                    <CourseList courses={data.programcourses.filter((item:any)=>{return !item.course || item.course.isProject})}/>
                :   <CardSkeleton/>
                }
                </Grid>
            </Grid>
        </Container>
    );
}

