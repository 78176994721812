import React, { useState, useContext } from 'react'
import { AxiosResponse } from 'axios';
import MetadataApi from '../apis/metadata.api';
import { myContext } from '../contexts/Context';
import { Alert, Button, Container, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';

type Props = {
    setPasswordDone: Function
}

export function ChangePasswordDialog({ setPasswordDone }: Props) {
    const [password, setPassword] = useState<string>('')
    const [actualPasswordError, setActualPasswordError] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>("")
    const [open, setOpen] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [passwordError, setPasswordError] = useState<string[]>([])
    const [passwordRepeatError, setPasswordRepeatError] = useState('')
    const ctx = useContext(myContext);
    const updatePassword = (event: any) => {
        event.preventDefault();
        setActualPasswordError('')
        const token = localStorage.getItem('token');
        setError('')
        if (passwordError.length>0 || passwordRepeatError !== '')
            return;
        if (ctx && ctx.user) {
            MetadataApi.post("/user/updatepassword", {
                verifyPassword: password,
                newPassword: newPassword,
                username: ctx.user.username
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token
                }),
            }).then((res: AxiosResponse) => {
                if (res.data === "Error") {
                    setActualPasswordError("Contraseña Actual incorrecta")
                }
                else {
                    setPasswordDone("success")
                    setOpen(!open)
                }

            })
        }

    }

    const validatePasswordRepeat = (passwordRepeat: string) => {
        if (passwordRepeat === newPassword) {
            setPasswordRepeatError('')
        } else {
            setPasswordRepeatError('Las contraseñas no son iguales!')
        }
    }
    const validatePassword = (password: string) => {
        const errorMessages: string[] = [];
        setNewPassword(password)
        if (password.length < 8) {
            errorMessages.push('La constraseña debe poseer al menos 8 caracteres');
        }
        // Other conditions can be checked separately
        if (!/[a-z]/.test(password)) {
            errorMessages.push('La contraseña debe poseer al menos una letra minúscula.');
        }

        if (!/[A-Z]/.test(password)) {
            errorMessages.push('La contraseña debe poseer al menos una letra mayúscula.');
        }

        if (!/\d/.test(password)) {
            errorMessages.push('La contraseña deboe poseer al menos un número.');
        }

        if (!/[^a-zA-Z\d]/.test(password)) {
            errorMessages.push('La contraseña debe poseer al menos un caracter especial.');
        }
        setPasswordError(errorMessages)

    }
    return (
        <>
            <Button variant='contained' size="small" onClick={() => { setOpen(true) }} sx={{ rowGap: 2 }}>Cambiar Contraseña</Button>
            {<Dialog open={open} onClose={() => { setOpen(false) }} fullWidth maxWidth="sm">
                <DialogTitle>Cambiar de Contraseña</DialogTitle>
                <DialogContent>
                    <Container className='centered' maxWidth="xs">

                        <TextField
                            margin="normal"
                            value={password}
                            fullWidth
                            name="actualPassword"
                            label="Contraseña Actual"
                            type="password"
                            id="actualPassword"
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            onChange={e => validatePassword(e.target.value)}
                            helperText={
                                passwordError.length > 0
                                    ? passwordError.map((message, index) => <div key={index}>{message}</div>)
                                    : ''
                            }
                            error={passwordError.length > 0}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="passwordrepeat"
                            label="Reingresar Nueva Contraseña"
                            type="password"
                            id="passwordrepeatmui textfield valida"
                            onChange={e => validatePasswordRepeat(e.target.value)}
                            error={passwordRepeatError !== ''}
                            helperText={passwordRepeatError}
                        />
                        {actualPasswordError !== '' ?
                            <Typography color={"error"}>Contraseña Actual incorrecta</Typography> : <></>}

                        <Grid display={"flex"} justifyContent={"flex-end"}>
                            <Button
                                onClick={() => { setOpen(false) }}
                                variant="outlined"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Cerrar
                            </Button>
                            <Button
                                onClick={updatePassword}
                                variant="contained"
                                sx={{ mt: 3, mb: 2, ml: 1 }}
                                disabled={password.length === 0 || newPassword.length === 0 || passwordRepeatError !== "" || password === ""}
                            >
                                Actualizar
                            </Button>
                        </Grid>

                        {error !== "" ? (
                            <Alert variant="filled" severity="error">
                                Error: Contraseña actual incorrecta
                            </Alert>
                        ) : (<></>)}

                    </Container>
                </DialogContent>
            </Dialog>}
        </>

    )
}