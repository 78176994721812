import {Card,CardActionArea,CardContent,Grid,Typography, useTheme,} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../stores/store";
import { Link } from "react-router-dom";

export function Notifications() {
  const notificationState = useSelector((state: RootState) => state.notifications);
  const theme = useTheme();
  const changeDateFormat = (date: Date) => {
    if (date) {
        const dateObject = new Date(date);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const hours = dateObject.getHours().toString().padStart(2, '0');
        const minutes = dateObject.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month} ${hours}:${minutes}`;
        return formattedDate
    } else {
        return " "
    }


}
  return (
    <Grid container spacing={1} p={5}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">Notificaciones</Typography>
      </Grid>
      {notificationState.notifications.map((notification: any, index: number) => {
       return(
        <Grid item xs={12}  key={index}>
        <Card sx={{ height: "100%" }}>
          <CardActionArea component={Link} to={"/learn/" + notification.courseId + "/" + notification.contentId}
          >
            <CardContent>
              <Typography>
              <span style={{ fontWeight: "bold", display: "inline" }}>
            Fecha: {changeDateFormat(notification.date)}               
              </span>
              </Typography>
              <Typography>
                <span style={{ fontWeight: "bold", display: "inline" }}>Nuevo mensaje en: </span>
                {" Curso "}
                <span style={{color:theme.palette.primary.main}}>
                {notification.course_name}
                </span>
                {". Contenido "}
                <span style={{color:theme.palette.primary.main}}>
                { notification.content_name}
                  </span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        </Grid>
       ) 
      })}
       
     
    </Grid>
  );
}
