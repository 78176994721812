import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItemText, ListItemButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box/Box';
import { getIcon } from './Icons';

type Props = {course:string,chapters:any}

const CourseDetail : React.FC<Props> = ({course,chapters}) => {
 
    return (
        <Box>
            {chapters.map( (chapter:any, i:number) => { return (
                <Accordion disableGutters key={i}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                        <Typography sx={{fontWeight: 'bold'}}>{chapter.name}: {chapter.description}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List component="nav">
                            {chapter.contents.map( (content:any, index:number, row:any) => { return (
                                <ListItemButton key={index} divider={(index+1===row.length?false:true)} sx={{gap:2}}>
                                    {getIcon(content.type,28)}
                                    <ListItemText primary={content.name} />
                                    {/* <Button component={Link} to={"/learn/"+course+"/"+content.contentId}>Iniciar</Button>       */}
                                </ListItemButton>
                            )})}
                        </List>
                    </AccordionDetails>
                </Accordion>
            )})}
      </Box>
  )}


  export default CourseDetail;