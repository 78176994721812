import { Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from "@mui/material";
import { CourseStatus } from "../enums/programs.enums";
import { useState } from "react";
import { Link } from "react-router-dom";

type Props = {
  status: CourseStatus;
  courseId: number;
  hours_limit: boolean;
  hours: number;
};
export function CourseLimitHours({
  status,
  courseId,
  hours_limit,
  hours,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      {status === CourseStatus.ToDo ? (
        hours_limit ? (
          <Button size="small" variant={"contained"} onClick={() => {setOpen(true)}}
          >
            Iniciar
          </Button>
        ) : (
          <Button variant="contained" component={Link} to={"/learn/" + courseId}
          >
            Iniciar
          </Button>
        )
      ) : (
        <Button size="small" variant={status === CourseStatus.Completed ? "outlined" : "contained"} component={Link} to={"/learn/" + courseId}
        >
          {status === CourseStatus.InProgress ? "Continuar" : "Completado"}
        </Button>
      )}

      {
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <DialogTitle>ATENCIÓN:</DialogTitle>
          <DialogContent>
            Este curso posee tiempo límite, al iniciar el curso comenzará una
            cuenta atrás de
            <span style={{ fontWeight: "bold" }}> {hours} horas</span>, el cual
            al llegar a 0 terminará el curso inhabilitando la posibilidad de
            volver a entrar. ¿Está usted seguro de continuar?
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => {   setOpen(false); }} sx={{ mr: 1 }}
            >
              Cerrar
            </Button>
            <Button variant="contained" component={Link} to={"/learn/" + courseId}
            >
              Iniciar
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}
