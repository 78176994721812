import React, {useContext,useEffect,useState} from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { Link } from 'react-router-dom';
import { myContext } from '../contexts/Context';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { CardSkeleton } from './CardSkeleton';
import { CourseStatus } from '../enums/programs.enums';
import Fab from '@mui/material/Fab';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Tooltip from '@mui/material/Tooltip';
import { CertificateIcon } from './Icons';

type Props = {
    courseId?:any
    percentage?:number,
}

const CourseCardSimple : React.FC<Props> = ({courseId,percentage}) => {
    const ctx = useContext(myContext);

    const theme = useTheme();

    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if(ctx.loading) return;
        setLoading(true);
        if(ctx && ctx.user){
            const token = localStorage.getItem('token');
            MetadataApi.post(`learncourses/getcourse`, { 
                courseId:courseId,
                username:ctx.user.username
            },{ headers: ({
                Authorization: 'Bearer ' + token
                }),
            }).then((res : AxiosResponse) => {
                //console.log('getcourse_learn:',res.data)
                setData(res.data)
                setLoading(false)
            }, () => {
                window.location.href = "/"
            })
        } else {
            MetadataApi.get(`courses/getcourse/${courseId}/data`, {
            }).then((res : AxiosResponse) => {
                //console.log('getcourse',res.data)
                setData(res.data)
                setLoading(false)
            }, () => {
                window.location.href = "/"
            })
        }
    },[ctx,courseId])

    return (
        <>
        { !loading ?
            <Card sx={{ border: "1px solid "+theme.palette.primary.main }}>
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'rows', gap:1 }}>
                        <Tooltip title={data.status===CourseStatus.ToDo ?'Iniciar':(data.status===CourseStatus.InProgress ?'Continuar':'Completado')}>
                            <Fab color="primary" size="small" component={Link} to={"/learn/"+courseId}><PlayArrowIcon/></Fab> 
                        </Tooltip>
                        <Typography variant="h6" component="div">
                            {data.name}
                        </Typography>
                        {data.certificate && data.certificate.code 
                            ? <CertificateIcon url={data.certificate.code}/>
                            : <></>
                        }
                        {percentage && percentage>0 ?
                            <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
                                <Box sx={{ width: '30%', mr: 1 }}>
                                    <LinearProgress sx={{height: 10, borderRadius: 5}} variant="determinate" value={percentage} />
                                </Box>
                                <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2" color="text.secondary">{`${percentage}%`}</Typography>
                                </Box>
                            </Box>
                            
                        : (null)}
                    </Box>
                </CardContent>
            </Card>
        :
            <CardSkeleton/>
        }
        </>
    );
}

export default CourseCardSimple;