import axios from 'axios';

export const getApiUrl = () => {
  const apiVersion = 'v1';
  const apiUrl = process.env.REACT_APP_MAIN_API;
  return `${apiUrl}/api/${apiVersion}`;
};

const MetadataApi = axios.create({
  baseURL: getApiUrl(),
});

export default MetadataApi;
