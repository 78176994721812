import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Box, Button, CircularProgress, Fab } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import ProjectDialog from './ProjectDialog';
import { ContentStatus } from '../enums/programs.enums';
import { CommentDialog } from './CommentDialog';
import * as DOMPurify from 'dompurify';
import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded';
import { myContext } from '../contexts/Context';
import './styles/imgJupyterlesson.css'
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CommentButton } from './CommentButton';
import { removeNotificationByIds } from '../stores/slices/notificationSlice';
import { NotificationType } from '../enums/notification.enum';

type Props = {
  item: any,
  handleSubmit: any,
  expired:boolean,
  isValidating:boolean,

}

const JupyterElement: React.FC<Props> = ({ item, handleSubmit,expired,isValidating }) => {



  //open the jupyternotebbok only when element is visible
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false)
  const ctx = useContext(myContext);
  const [isJupyterLoaded, setIsJupyterLoaded] = useState<boolean>(false)
  const [url, setURL] = useState<string>();
  const [loadingJupyter, setLoadingJupyter] = useState<boolean>(true);
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const courseState = useSelector((state: RootState) => state.activeCourse);
  const cleanData = (html: string) => {
    return DOMPurify.sanitize(html)
  }
  useEffect(() => {
    if (courseState.course.isProject && (courseState.content.status === ContentStatus.Completed || courseState.content.status === ContentStatus.Failed)) {
      setOpen(true)
      //remover notificacion
      const token = localStorage.getItem('token');
      MetadataApi.post("/learncomments/removenotification/", {
        contentId: item.contentId,
        codelessonId: item.id,
        username: ctx.user?.username,
        type:NotificationType.Project
    }, {
        headers: ({
            Authorization: 'Bearer ' + token
        })
    }).then(()=>{
      dispatch(removeNotificationByIds({ contentId: item.contentId,codelessonId:item.id,type:'Project' }))
    })
      
    }
  }, [courseState.content.status, courseState.course.isProject,ctx.user?.username,dispatch,item.contentId,item.id])

  useEffect(() => {
    let observerRefValue: any = null;
    const observer = new IntersectionObserver(([entry]) => {
      if (!isVisible && entry.isIntersecting)
        setIsVisible(entry.isIntersecting);
    });
    if (ref.current) {
      observer.observe(ref.current);
      observerRefValue = ref.current;
    }
    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue);
    };
  }, [isVisible, ref]);



  


  const getJupyterNotebookUrl = useCallback(() => {
    setLoadingJupyter(true);
    const token = localStorage.getItem('token');
    MetadataApi.post("learncontents/getjupyternotebookstudent", {
      courseId: courseState.course.courseId,
      chapterId: courseState.course.chapters[courseState.locationchapter].chapterId,
      contentId: item.contentId,
      username: ctx?.user?.username,
      jupyterId: item.id,
    }, {
      headers: ({
        Authorization: 'Bearer ' + token
      }),
    }).then((res: AxiosResponse) => {
      console.log('getURL:', res.data);
      if (res.data !== "code error") {
        setURL(res.data);
        setIsJupyterLoaded(true)
      }
      else {
        console.log('getURL ERROR:');
        setIsJupyterLoaded(false)
      }
      setLoadingJupyter(false)
    }, () => {
      // window.location.href = "/"
    })
  }, [courseState.course, ctx.user?.username, item.contentId, item.id, courseState.locationchapter])
  useEffect(() => {
    if (!item.id || !isVisible || !ctx.user) return;
    if (!isMounted.current) {
      isMounted.current = true
      getJupyterNotebookUrl()
    }
  }, [item, isVisible, ctx.user, isMounted, getJupyterNotebookUrl])

  const handleClose = () => {
    setOpen(false)
  }
  const handleValidate = (canValidate: boolean) => {
    handleSubmit(canValidate);
    setOpen(false)
  
  }


  return (
    <Grid ref={ref} container spacing={2} position={"relative"}>
      <Grid item xs={12} md={5}>
        <Box sx={{
          '&::-webkit-scrollbar': {
            width: 0
          }, maxHeight: '60vh', overflow: 'auto', minHeight: '30vh',
        }}>
          <Typography onCopy={(e)=>{e.preventDefault()}} sx={{userSelect:"none",WebkitUserSelect:"none",WebkitTouchCallout:"none",msUserSelect:"none",MozUserSelect:"none"}} variant='h6'>{item.title}</Typography>
          <Typography onCopy={(e)=>{e.preventDefault()}} sx={{userSelect:"none",WebkitUserSelect:"none",WebkitTouchCallout:"none",msUserSelect:"none",MozUserSelect:"none"}} variant='inherit' dangerouslySetInnerHTML={{ __html: cleanData(item.detail) }} />
        </Box>
      </Grid>
      <Grid item xs={12} md={7} >
        {courseState.content.status === ContentStatus.Pending ?
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}  >
            <Grid container>
              <Grid item xs={12}><Typography variant='h5' sx={{ textAlign: "center" }}>Esperando revisión de la entrega del JupyterNotebook</Typography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} ><Typography variant='subtitle1' >Se habilitará luego de obtener el feedback del profesor  </Typography></Grid>
            </Grid>
          </Box>
          :
          loadingJupyter ?
            <Box sx={{
              display: 'flex', flexDirection: 'column', minHeight: "73vh",  alignItems: 'center', borderWidth: 0.5,
              justifyContent: 'center', width: "100%", height: '100%',maxHeight:"72vh"
            }}>
              <CircularProgress />
            </Box>
            :
            isJupyterLoaded ?

              <Grid container sx={{ height: '100%' }} >
                <Grid item xs={12} sx={{ height: '100%' }}>

                  <iframe className='iframe' scrolling='no' title='Jupyter'
                    src={url}
                    width="100%"
                    height='100%'
                    style={{ minHeight: "73vh" }}>
                  </iframe>

                </Grid>

                
                <Grid item xs={12} md={12} justifyContent={"flex-end"} display={'flex'} position={"relative"} >
                  {!courseState.course.isProject?
                    <Fab onClick={() => { handleValidate(true) }} disabled={expired||isValidating} variant='extended' size="small" color="primary" aria-label="add"
                      sx={{ position:"absolute",bottom:25,right:10, display: { xs: 'flex', md: 'none' } }}>
                      <PlaylistPlayRoundedIcon fontSize='large' />
                      <Typography variant='body2' sx={{ mr: 1 }}>{expired?"Expirado": isValidating?"Validando":"Validar"} {isValidating?<CircularProgress size={12} color="inherit" />:null} </Typography>
                    </Fab> : null
                  }
                </Grid>
              </Grid>
              :
              <Box sx={{
                height: '100%', display: 'flex', flexDirection: 'column', minHeight: "70vh", alignItems: 'center', borderWidth: 0.5,
                justifyContent: 'center'
              }}>
                <Grid container>
                  <Grid item xs={12}><Typography sx={{ textAlign: "center" }}>Error al Cargar JupyterNotebook</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} ><Button variant='contained' onClick={getJupyterNotebookUrl} >Recargar <ReplayIcon></ReplayIcon> </Button></Grid>
                </Grid>
              </Box>
        }
          {courseState.course.isProject && (courseState.content.status === ContentStatus.Completed || courseState.content.status === ContentStatus.Failed) ?
        <CommentButton contentId={item.contentId} /> : null

      }
      </Grid>
        {
        expired?<Fab variant='extended' size="small"  sx={{ position:"absolute",bottom:25,right:10, display: { xs: 'none', md: 'flex', sm: 'none' } }} disabled>Expirado</Fab>:
        courseState.course.isProject ?
          <ProjectDialog isJupyterLoaded={isJupyterLoaded} contentId={item.contentId} contentTitle={item.title}></ProjectDialog>
          :
          isJupyterLoaded ?
            item.canValidate ?
              isValidating? 
              <Fab disabled variant='extended' size="small" color="primary" aria-label="add"
                sx={{ position:"absolute",bottom:25,right:10, display: { xs: 'none', md: 'flex', sm: 'none' } }}>
                <PlaylistPlayRoundedIcon fontSize='large' /> <Typography variant='body2' sx={{ mr: 1 }}>Validando </Typography>
                <CircularProgress size={20} color="inherit" />
              </Fab>
              :
              <Fab onClick={() => { handleValidate(true) }} variant='extended' size="small" color="primary" aria-label="add"
                sx={{ position:"absolute",bottom:25,right:10, display: { xs: 'none', md: 'flex', sm: 'none' } }}>
                <PlaylistPlayRoundedIcon fontSize='large' /> <Typography variant='body2' sx={{ mr: 1 }}>Validar </Typography>
              </Fab> 
            :
            <Fab onClick={() => { handleValidate(false) }} variant='extended' size="small" color="primary" aria-label="add"
              sx={{position:"absolute",bottom:25,right:10, display: { xs: 'none', md: 'flex', sm: 'none' } }}>
              <ArrowForwardIcon fontSize='medium' /> <Typography variant='body2' sx={{ mr: 1 }}>Siguiente </Typography>
            </Fab>
          : null
        }
   {/*      <ChatBox contentId={item.contentId} codelessonId={item.id} numberNotifications={numberNotifications} setNumberNotifications={setNumberNotifications} getNumberNotifications={getNumberNotifications} ></ChatBox> */}
  
     
    
      {courseState.course.isProject && !expired ? <CommentDialog showAll={false} open={open} onClose={handleClose} contentId={item.contentId}></CommentDialog> : null}

    </Grid>

  )
}

export default JupyterElement;