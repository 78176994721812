import Card from '@mui/material/Card';
import { Typography,CardContent, Skeleton, List, Container, ListItem } from '@mui/material';


export function HelpSkeleton() {
    return (
        <>
            <List sx={{ listStyleType: 'disc', pl: 4, paddingBottom: 5 }}>

                <ListItem sx={{ display: 'list-item' }} disablePadding>
                    <Skeleton></Skeleton>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }} disablePadding>
                    <Skeleton></Skeleton>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }} disablePadding>
                    <Skeleton></Skeleton>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }} disablePadding>
                    <Skeleton></Skeleton>
                </ListItem>
            </List>

            <Container sx={{ paddingBottom: 5 }}>

                <Card sx={{ height: '100%', width: '100%' }}>
                    <CardContent>
                        <Typography variant="h6" component="div">
                            <Skeleton />
                        </Typography>
                        <Typography variant="body2" >
                            <Skeleton />

                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ height: '100%', width: '100%' }}>
                    <CardContent>
                        <Typography variant="h6" component="div">
                            <Skeleton />
                        </Typography>
                        <Typography variant="body2" >
                            <Skeleton />

                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ height: '100%', width: '100%' }}>
                    <CardContent>
                        <Typography variant="h6" component="div">
                            <Skeleton />
                        </Typography>
                        <Typography variant="body2" >
                            <Skeleton />

                        </Typography>
                    </CardContent>
                </Card>

            </Container>


        </>
    );
}
