import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { UserContext, UserInterface } from '../interfaces/UserInterface'
import MetadataApi from '../apis/metadata.api'

export const myContext = createContext<Partial<UserContext>>({})

export default function Context(props: PropsWithChildren<any>) {

  const [user,setUser] = useState<UserInterface>()
  const [loading, setLoading] = useState<boolean>(true)
  
  useEffect(() => {
    setLoading(true)
    const token = localStorage.getItem('token');
    
    if(!token){ setLoading(false); return;}
    MetadataApi.get("/auth/user", { 
      // withCredentials: true,
      headers: ({
        Authorization: 'Bearer ' + token
      })
    })
    .then((res: AxiosResponse) => {
      // console.log('login info',res.data)
      res.data["updateUser"]=setUser
      /* res.data["updateLastName"]=updateLastname */
      /* console.log(res.data) */
      
      setUser(res.data);
      setLoading(false);
    }, () => {
      localStorage.removeItem('token');
      console.log('Login error')
      window.location.href = "/login"
    })
  }, []);

  return (
    <myContext.Provider value={{user,loading}}>{props.children}</myContext.Provider>
    )
}