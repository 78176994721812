import { useEffect, useState } from 'react';
import { AppBar, Container, Grid, IconButton, Typography, useTheme, Link, Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import MetadataApi from '../apis/metadata.api';
import { AxiosResponse } from 'axios';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import { alertDialogOpen } from '../stores/slices/alertSlice';
export default function AlertBar() {

    const [countdown, setCountdown] = useState<number>(0);
    const [alert, setAlert] = useState<any>()
    const [barColor, setBarColor] = useState<string>('')
    const [borderBarColor, setBorderBarColor] = useState<string>('')
    const [alertEnabled,setAlertEnabled]=useState<boolean>(false)
    const theme = useTheme();
    const dispatch = useDispatch();
    const alertState = useSelector((state: RootState) => state.alert);
    useEffect(() => {
        const token = localStorage.getItem('token');
        MetadataApi.get("alerts/getlastalert", {
            headers: ({
                Authorization: 'Bearer ' + token
            }),
        }).then((res: AxiosResponse) => {
            if (res.data !== "") {
                let finalColor = ''
                let finalBorderColor = '#f44336'
                if (res.data.color === "red") {
                    finalColor = "#f6685e"
                } else if (res.data.color === "yellow") {
                    finalColor = '#ffcd38'
                    finalBorderColor = '#ffc107'
                } else if (res.data.color === "green") {
                    finalColor = "#a2cf6e"
                    finalBorderColor = '#8bc34a'
                }
                dispatch(alertDialogOpen(true))
                setBarColor(finalColor)
                setBorderBarColor(finalBorderColor)
                setAlert(res.data)
                const maintenanceStartTime = new Date(res.data.finish_date)
                const currentTime = new Date();
                const timeDifference = maintenanceStartTime.getTime() - currentTime.getTime();
                const secondsRemaining = Math.floor(timeDifference / 1000);
                setCountdown(Math.max(0, secondsRemaining));
                setAlertEnabled(true)
            }

        })
    }, [dispatch])
    useEffect(() => {
        if (alertEnabled) {
            setInterval(() => {
                setCountdown((prevCountdown) => Math.max(0, prevCountdown - 1));
            }, 1000);
        }
    }, [alertEnabled]);

    // Format the seconds into hours, minutes, and seconds
    const hours = Math.floor(countdown / 3600);
    const minutes = Math.floor((countdown % 3600) / 60);
    const seconds = countdown % 60;

    return (
        <>
            {alertState.alertOpen && alertEnabled ? (
                <AppBar position="relative" sx={{ minHeight: "40px", zIndex: theme.zIndex.drawer + 1, bgcolor: barColor,border: `5px solid ${borderBarColor}`, justifyContent: "center" }}>
                    <Container maxWidth="xl">
                        <Grid container >
                            <Grid item xs={1} sm={1} md={1} lg={1}/>
                            <Grid item xs={10} sm={10} md={10} lg={10}>
                               {/*  For the moment only ask for red, but can be more colors */}
                                {alert.color === 'red' ? (
                                    <Box sx={{ alignContent: "center", justifyContent: "center", display: "flex" }}>
                                        <WarningIcon sx={{ color: 'black', mr: "6px" }} />
                                        <Typography align="center" color="black">
                                            {alert.message}{' '}
                                            {alert.link !== null ? (
                                                <Link href={alert.link} target="_blank">
                                                    {alert.link}
                                                </Link>
                                            ) : (
                                                ''
                                            )}
                                        </Typography>
                                        <Typography variant="body1" ml={1} align="center" color="black">
                                            Tiempo Restante:
                                            <span style={{ fontWeight: 'bold' }}>
                                                {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                                            </span>
                                        </Typography>
                                    </Box>

                                ) : (
                                    <Box sx={{ alignContent: "center", justifyContent: "center", display: "flex" }}>

                                        <InfoIcon sx={{ color: 'black', mr: "6px", }} />
                                        <Typography align="center" color="black">
                                            {alert.message}{'. '}
                                            {alert.link !== null ? (
                                                <>
                                                    Más información {""}
                                                    <Link href={alert.link} target="_blank">
                                                        aquí
                                                    </Link>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} />
                            <IconButton sx={{ position: "absolute", right: 10,top:-2 }} size="small" onClick={() => dispatch(alertDialogOpen(false))}>
                                <CloseIcon />
                            </IconButton>

                        </Grid>
                    </Container>
                </AppBar>
            ) : null}
        </>
    );

}