
import { AxiosResponse } from 'axios';
import { useState, useEffect, Fragment, useContext, useRef } from 'react';
import MetadataApi from '../apis/metadata.api';
import { Chat } from './Chat';
import { useTheme } from '@mui/material/styles';
import { Badge, Box, Button, Fab, IconButton, List, TextField, Typography } from '@mui/material';
import { myContext } from '../contexts/Context';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { WebSocketContext } from '../contexts/WebsocketContext';
import { Role } from '../enums/roles.enums';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import { OpenChat, removeNotificationByIds } from '../stores/slices/notificationSlice';
import { NotificationType } from '../enums/notification.enum';

type Props = {
    contentId: number,
    codelessonId: any,
    numberNotifications: number,
    setNumberNotifications:Function,
    getNumberNotifications:Function,
    page:number,
    multiplesChats:boolean
}
export function ChatBox({ contentId, codelessonId, numberNotifications,setNumberNotifications,getNumberNotifications,page,multiplesChats}: Props) {
    const socket = useContext(WebSocketContext)
    const [chat, setChat] = useState<any>([])
    const [newComment, setNewComment] = useState<string>("")
    const [open, setOpen] = useState<boolean>(false)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [disabledSend, setdisabledSend] = useState<boolean>(false)
    const [isTeacherWriting, setIsTeacherWriting] = useState<boolean>(false)
    const dispatch = useDispatch();
    const boxRef = useRef<HTMLDivElement | null>(null);
    const courseState = useSelector((state: RootState) => state.activeCourse);
    const ctx = useContext(myContext)
    const theme = useTheme();

    useEffect(() => {
        if(socket){
           
            socket.on('onMessageTeacher', (data) => {
                if (open) {
                    data["user_type"] = Role.TeacherAssistant
                    data["date"] = new Date().toJSON()
                    setChat((oldChat: any) => [...oldChat, data])
                }else{
                    getNumberNotifications(page)
                }
            })
            socket.on('onWriting', (data) => {
                if (open && data.contentId===contentId&&data.codelessonId===codelessonId) {
                    setIsTeacherWriting(data.writing)
                    if (boxRef.current) {
                        // Scroll to the bottom when content changes
                        boxRef.current.scrollTop = boxRef.current.scrollHeight;
                    }
                }
            })
    
            return () => {
                socket.off('connect')
                socket.off('onMessageTeacher')
                socket.off('onWriting')
            }   
        }
       
    }, [socket, open,getNumberNotifications,codelessonId,contentId,courseState.course.courseId,page])
    useEffect(() => {
        if (boxRef.current) {
            // Scroll to the bottom when content changes
            boxRef.current.scrollTop = boxRef.current.scrollHeight;
        }
    }, [chat]);
    const handleOpen = () => {

        if (open) {
            setOpen(false)
            dispatch(OpenChat(false))
        } else {

            if (!ctx || !ctx.user) return
            const token = localStorage.getItem('token');
            dispatch(removeNotificationByIds({ contentId: contentId,codelessonId:codelessonId,type:NotificationType.Chat }))
            setNumberNotifications(0)
            MetadataApi.post("/learncomments/removenotification/", {
                contentId: contentId,
                codelessonId: codelessonId,
                username: ctx.user?.username,
                type:NotificationType.Chat
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token
                })
            })
            MetadataApi.post("learncomments/getchatuser", {
                contentId: contentId,
                username: ctx?.user?.username,
                codelessonId: codelessonId,
                courseId:courseState.course.courseId
            }, {
                headers: ({
                    Authorization: 'Bearer ' + token
                }),
            }).then((res: AxiosResponse) => {
                dispatch(OpenChat(true))
                setChat(res.data)
                setOpen(true)
                setIsLoaded(true)
            })
        }

    }
    const handleClose = () => {
        setOpen(false)
        setNewComment("")
        dispatch(OpenChat(false))
    }
    const boxStyles = {
        borderRadius: "10px",
        overflow: 'auto',
        position: 'fixed',
        top: 170,
        right: 30,
        width: '67%',
        maxHeight: '370px',
        bgcolor: '#EAEAEA',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            width: '35%', 
            zIndex: 1,
            top: 220
        },
    };

    const fabStyle = {
        position: "absolute", right: 60,
    };
    useEffect(() => {
        if (newComment === "") {
            setdisabledSend(true)
        } else {
            setdisabledSend(false)
        }
    }, [newComment])

    const addChat = (e: any) => {
        e.preventDefault()
        setdisabledSend(true)
        if (!ctx || !ctx.user) return
        const token = localStorage.getItem('token');
        MetadataApi.post("learncomments/addchatuser", {
            contentId: contentId,
            username: ctx?.user?.username,
            codelessonId: codelessonId,
            comment: newComment,
        }, {
            headers: ({
                Authorization: 'Bearer ' + token
            }),
        }).then((res: AxiosResponse) => {
            setChat(res.data)
            setNewComment("")
            if(socket){
                socket.emit('newMessageStudent', {
                    user_comment: ctx?.user?.username,
                    comment: newComment,
                })
            }
           
        })
    }

    useEffect(() => {
        if(socket){
            let isWriting = false
            if (newComment !== "") {
                isWriting = true
            }
    
            socket.emit("writing", { username: ctx.user?.username, writing: isWriting, contentId: contentId, codelessonId: codelessonId,role:Role.User })
    
        }
       
    }, [newComment, ctx.user, socket, codelessonId, contentId])

    useEffect(()=>{
        if(multiplesChats)setOpen(false)
        
    },[page,multiplesChats])
    const handleInputChange = (e: any) => {
        setNewComment(
            e.target.value
        )
    }
    return (
        <>
        <Box sx={fabStyle}>
            <br/>
        <Badge badgeContent={numberNotifications} color='error' >
                <Button sx={{color:"white",backgroundColor:"green",borderRadius:"50%",boxShadow: 3,minWidth: 40,minHeight: 40,'&:hover': {backgroundColor: "darkgreen",}
                }}
                onClick={handleOpen} disabled={process.env.REACT_APP_CHAT_ENABLED === "TRUE" ? false : true} >
                    <QuestionAnswerIcon />
                </Button>      
            </Badge>
        </Box>
            {open ?
                <Box sx={boxStyles}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: "#e0e0e0"
                        }}
                    >
                        <Typography sx={{ marginLeft: 1 }}
                            variant='body1'>Chat</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </Box>
                    <Box sx={{ maxHeight: "250px", height: "250px" }}>

                        <Box sx={{ backgroundColor: "#EAEAEA", height: "100%", display: "flex", flexDirection: "column" }}>
                            {/* Content for the first DialogContent */}
                            {isLoaded ? (
                                <Box ref={boxRef} sx={{ width: '100%', height: "100%", overflow: "auto" }}>
                                    {chat.length === 0 ? <Typography color={"grey"} align='center'>Conversación no empezada</Typography> : (
                                        <List sx={{ width: '100%', bgcolor: '#EAEAEA' }}>
                                            {chat.map((comment: any, index: number) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <Chat index={index} comment={comment}></Chat>
                                                    </Fragment>
                                                )
                                            })}
                                        </List>
                                    )}
                                    {isTeacherWriting ? <Typography align='right' sx={{ marginRight: 1 }}>
                                        Escribiendo...
                                    </Typography> : null}

                                </Box>
                            ) : null}
                        </Box>

                    </Box>
                    {/* Content for the second DialogContent */}
                    <Box sx={{
                        backgroundColor: "#d3d3d3", alignItems: "center", justifyContent: "center", padding: "8px",
                    }}>
                        <form onSubmit={addChat} >
                            <Box display="flex" alignItems="flex-end">
                                <TextField
                                    sx={{ backgroundColor: "white" }}
                                    required
                                    size='small'
                                    id="newComment"
                                    name="newComment"
                                    label="Nuevo Mensaje"
                                    type="text"
                                    value={newComment}
                                    onChange={handleInputChange}
                                    multiline
                                    fullWidth
                                />
                                <Fab color='success' disabled={disabledSend} size='small' type='submit'>
                                    <SendIcon fontSize='small' />
                                </Fab>
                            </Box>
                        </form>
                    </Box>
                </Box>
                : null
            }
        </>
    )
}